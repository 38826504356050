
export const commonLeftCompStyle = (Theme) => ({

  mainHeading: {
    "font-family": "'MuseoSans'",
    fontSize: 22,
    color: "#000000",
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 26,
    },
  },
  navigationBox: {
    margin: "40px 0",
    backgroundColor: "#ffffff",
    [Theme.breakpoints.up(`${"md"}`)]: {
      margin: "40px 40px 0 40px",
    },
  },
  saveFeedback:{
    "font-family": "'MuseoSans 500'",
    color: "#E35205",
    fontSize: 16,
  },
  sideIcon: {
    paddingLeft: 10,
    position: "relative",
    top: 2,
  },
  privateSideIcon: {
    paddingLeft: 10,
    position: "relative",
    top: 4,
  },
  refreshContainer: {
    textAlign: "center",
  },
  revenueHeading: {
    "font-family": "'MuseoSans 500'",
    color: "#000000",
    fontSize: 16,
    marginTop: 4
  },
  mainHeading2: {
    "font-family": "'MuseoSans 500'",
    color: "#E35205",
    fontSize: 16,
    marginTop: 42,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 32,
    },
  },
  mainHeading3: {
    marginTop: 22,
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 18,
    lineHeight: 1.2,
  },
  mainHeading4: {
    marginTop: 42,
    "font-family": "'MuseoSans 500'",
    color: "#E35205",
    fontSize: 14,
  },
  formula: {
    marginTop: 11,
    marginBottom: 20,
  },
  formula1: {
    width: "20rem",
    height: "40px",
    marginTop: "11px",
  },
  formula2: {
    width: "20rem",
    height: "40px",
    marginTop: "11px",
  },
  formula3: {
    width: "19rem",
    height: "40px",
    marginTop: "11px",
  },
  formula4: {
    width: "20rem",
    height: "40px",
    marginTop: "11px",
  },
  formula5: {
    width: "16rem",
    height: "40px",
    marginTop: "11px",
    marginLeft: "-30px",
  },
  formula6: {
    width: "11rem",
    height: "40px",
    marginTop: "11px"
  },
  sliderbox: {
    marginTop: 30,
  },
  bodyText: {
    paddingTop: 23,
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 14,
  },
  parameter: {
    fontSize: 14,
    "font-family": "'MuseoSans'",
  },
  text: {
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 14,
  },
  spacing: {
    [Theme.breakpoints.down(`${"md"}`)]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: "20px",
    marginLeft: "-20px",
  },
  paddingX: {
    [Theme.breakpoints.up(`${"md"}`)]: {
      padding: "0px 5px",
    },
  },
  hideItem: {
    display: "none",
  },
  buttonIcon: {
    margin: 2,
  },
  baseText: {
    textAlign: "justify",
    marginLeft: 35,
    marginTop: 20,
    marginRight: 35,
    marginBottom: 30,
    padding: 0,
    "font-family": "'MuseoSans'",
    color: "#E35205",
    fontSize: 13,
    [Theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  warningBox: {
    width: "100%",
    padding: 8,
    marginTop: 20,
    backgroundColor: "#FFFFFF",
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px,
         rgb(0 0 0 / 14%) 0px 2px 2px 0px,
          rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 25,
      width: "fit-content",
    },
    "& .MuiIconButton-label": {
      paddingRight: 18,
      paddingLeft: 10,
    },
  },
  containerRightShadow: {
    "box-shadow": "30px 2px 15px -21px rgba(0,0,0,0.07)",
    width: "100%",
    height: "100%",
  },
  textWrap: {
    "font-family": "'MuseoSans'",
    color: ["#121212", "!important"],
  },
  bslable: {
    "font-family": "'MuseoSans'",
    fontSize: [14, "!important"],
    color: ["#000000", "!important"],
  },
  mainButton: {
    width: "100%",
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
  },
  buttonCenter: {
    margin: "20px 0px",
    [Theme.breakpoints.up("md")]: {
      justifyContent: "left",
    },
  },
  paddingButton: {
    padding: 9,
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
    color: "#004C97",
    width: "100%",
    textAlign: "center",
    [Theme.breakpoints.up(`${"md"}`)]: {
      width: "fit-content",
      marginRight: 10,
    },
  },
  alertTooltip: {
    paddingTop: 18,
    paddingLeft: 10,
  },
  infoIcon: {
    paddingLeft: 5,
    position: "relative",
    top: 5,
  },
  privateInfo: {
    paddingLeft: 5,
    position: "relative",
  },
  warnText: {
    marginLeft: 6,
    marginRight: 33,
    position: "relative",
    top: -5,
    padding: 0,
    fontSize: 14,
    "font-family": "'MuseoSans'",
    color: "#000000",
  },
  switch: {
    marginTop: 23,
    "& span": {
      "font-family": "'MuseoSans'",
      textColor: "#000000",
      fontSize: 14,
    },
  },
  buttonTopMargin: {
    marginTop: 20,
  },

  dataHeader: {
    "border-bottom": "1px solid #dddddd",
    paddingBottom: 10,
  },
  tableMarginTop: {
    marginTop: 16,
  },
  tableHeaderText: {
    textAlign: "right",
    paddingRight: 5,
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 14,
    },
  },
  text2: {
    textAlign: "center",
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 14,
    },
  },
  tableRowPadding: {
    padding: "10px 5px",
    "border-bottom": "1px solid #ebebeb",
    "border-left": "5px solid #F8FAFC",
    alignItems: "center",
    minHeight: 60,
  },
  iconBox: {
    whiteSpace: "nowrap",
    textAlign: "left",
  },
  revenueIconBox: {
    whiteSpace: "nowrap",
    [Theme.breakpoints.up(`${"sm"}`)]: {
      paddingLeft: 20,
    },
  },
  InfoSpecs: {
    display: "inline",
    margin: "auto",
  },
  revenueInfo: {
    display: "inline",
    margin: "auto",
    paddingLeft: 5,
  },
  iconSpecs: {
    display: "none",
    paddingRight: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 10,
    },
  },
  sliderAlignment: {
    marginTop: 15,
    [Theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  sliderIconSpecs: {
    paddingRight: 15,
    cursor: "pointer",

    "&:first-child": {
      paddingLeft: 10,
    },
  },
  saveIcon: {
    paddingLeft: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 5,
    },
    marginTop: 10,
  },
  macroBlurSave: {
    opacity: 0.5,
    marginTop: 10,
    paddingRight: 5,
    display: "none",
  },
  macroSaveIcon: {
    paddingRight: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 5,
    },
    marginTop: 10,
  },
  nonInputTooltip: {
    whiteSpace: "nowrap",
    [Theme.breakpoints.up(`${"sm"}`)]: {
      paddingLeft: 12,
    },
  },
  tableDataText: {
    "font-family": "'MuseoSans'",
    color: "#121212",
    fontSize: 14,
    textAlign: "right",
    paddingRight: 5,
  },
  tableDataText2: {
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 14,
    textAlign: "right",
    paddingRight: 5,
  },
  tbleTxtNoPadding: {
    "font-family": "'MuseoSans'",
    color: "#121212",
    fontSize: 14,
  },
  totalTbleTxtNoPadding: {
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 14,
  },
  totalTbleTxtMore: {
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 14,
  },
  totalTbleTxtMorefield: {
    "font-family": "'MuseoSans'",
    color: "#121212",
    fontSize: 14,
  },
  tableText: {
    paddingLeft: 17,
    "font-family": "'MuseoSans'",
    color: "#121212",
    fontSize: 14,
  },
  subLabel: {
    paddingLeft: 12,
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 14,
  },
  tableText2: {
    paddingLeft: 17,
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 14,
  },
  totalField: {
    "font-family": "'MuseoSans 500'",
    fontSize: 14,
    paddingRight: 5,
    color: "#121212",
  },
  grandTotal: {
    fontFamily: "'MuseoSans 500'",
    paddingRight: 5,
    color: "#121212",
    fontSize: 14,
  },
  filterGreen: {
    filter: "sepia(0.7)",
    backgroundColor: "red"
  },
});
