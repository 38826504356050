import * as React from "react";
import { Typography, Box } from "@material-ui/core";
import { footerStyles } from "../css/custom";

export default function Footer() {
  const classes = footerStyles();

  return (
        <Box className={classes.footerStyle}>
          <Typography variant="caption" 
          color="initial"
          className={classes.textPadding}
          >
          © 2022 INTERNATIONAL MONETARY FUND. ALL RIGHTS RESERVED.
          </Typography>
          </Box>
  );
}