import React from "react";
import { Grid } from "@material-ui/core";
import {
  Chart,
  Series,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Legend,
  Margin,
  Title,
  Subtitle,
  Tooltip,
} from "devextreme-react/chart";

function LineGraphDevExtreme({
  chartLabels,
  chartDataSource,
  chartTitle,
  chartSubTitle,
  customClassName,
}) {

  return (
    <>
      <Chart
        palette="Violet"
        dataSource={chartDataSource}
        className={customClassName || ""}
        style={{
          border: "solid",
          borderWidth: "1.5px",
          borderRadius: "15px",
          borderColor: "#009CDE",
        }}
      >
        <CommonSeriesSettings argumentField="year" />
        {chartLabels.map((item) => (
          <Series
            key={item.value}
            valueField={item.value}
            name={item.name}
            color={item.color || ""}
          />
        ))}
        <Margin bottom={20} />
        <ArgumentAxis
          valueMarginsEnabled={false}
          discreteAxisDivisionMode="crossLabels"
        >
          <Grid visible={true} />
        </ArgumentAxis>
        <Legend
          verticalAlignment="bottom"
          horizontalAlignment="center"
          itemTextPosition="bottom"
        />
        <Export enabled={true} />
        <Title text={chartTitle}>
          <Subtitle text={chartSubTitle || ""} />
        </Title>
        <Tooltip enabled={true} />
      </Chart>
    </>
  );
}

export default LineGraphDevExtreme;
