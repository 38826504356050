import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import loadable from "@loadable/component";
import { getStore } from "../store/reducerSlice";
import { connect, useSelector } from "react-redux";
import AdobeTracker from "../utils/adobeTracker";

const OverviewInfoPage = () => {
  window.location.href = "/sdginfopages/index.html";
};
const UserGuideInfoPage = () => {
  window.location.href = "/sdginfopages/user_guide.html";
};
const FrameworkInfoPage = () => {
  window.location.href = "/sdginfopages/framework.html";
};
const AdvHumanCapital = loadable(() =>
  import(/* webpackChunkName: "advHumCap" */ "../baseline/advHumanCapital")
);
const Dashboard = loadable(() =>
  import(/* webpackChunkName: "dashboard" */ "../dashboard/ComparisonDashboard")
);
const SelectCountry = loadable(() =>
  import(/* webpackChunkName: "index" */ "../selectCounrty/index")
);
// financing tab
const FinRevenue = loadable(() =>
  import(/* webpackChunkName: "Revenue" */ "../baseline/financialRevenue")
);
const FinSpending = loadable(() =>
  import(/* webpackChunkName: "spending" */ "../baseline/financialSpending")
);
const DebtFin = loadable(() =>
  import(/* webpackChunkName: "debtFinance" */ "../baseline/financialDebt")
);
const finOverview = loadable(() =>
  import(/* webpackChunkName: "finOverview" */ "../baseline/financialOverview")
);
const privateFinance = loadable(() =>
  import(
    /* webpackChunkName: "pvtFinance" */
    "../baseline/financialPrivateFinancing"
  )
);

const BaselineOve = loadable(() =>
  import(/* webpackChunkName: "bsOverview" */ "../baseline/BaselineOverview")
);

const AdvPhysicalCapital = loadable(() =>
  import(/* webpackChunkName: "advPhyCap" */ "../baseline/advPhysicalCapital")
);
const AdvProductionFunction = loadable(() =>
  import(/* webpackChunkName: "ProdFns" */ "../baseline/advProductionFunction")
);
const MacroInFlation = loadable(() =>
  import(/* webpackChunkName: "inflation" */ "../baseline/macroInflation")
);

const AdvOverview = loadable(() =>
  import(/* webpackChunkName: "advOverview" */ "../baseline/advOverview")
);

const macOverview = loadable(() =>
  import(/* webpackChunkName: "macroOverview" */ "../baseline/macroOverview")
);

const MacroExternalSector = loadable(() =>
  import(/* webpackChunkName: "extSect" */ "../baseline/macroExternalSector")
);

const MacroGDP = loadable(() =>
  import(/* webpackChunkName: "GDP" */ "../baseline/macroGDP")
);

const costOverview = loadable(() =>
  import(/* webpackChunkName: "costOverview" */ "../baseline/costingOverview")
);
const sdgCost = loadable(() =>
  import(/* webpackChunkName: "costSDG" */ "../baseline/costingSDGCost")
);
const CostingInitials = loadable(() =>
  import(/* webpackChunkName: "Initials" */ "../baseline/costingInitials")
);
// const pdf = loadable(() =>
//   import(/* webpackChunkName: "pdf" */ "../Pdf/ExportPdf")
// );

const PrivateRoute = ({ component: Component, ...rest }) => {
  const country = useSelector(getStore);
  const [data, setData] = useState(country);
  const [count, setCount] = useState(0);
  const location = useLocation();
  const responseStatus = country.value.responseStatus;

  useEffect(() => {
    setData(country.baselineScenarioData);
  }, [country.baselineScenarioData, responseStatus]);

  useEffect(() => {
    setData(country.baselineScenarioData);
  }, [responseStatus]);

  useEffect(() => {
    AdobeTracker();
    setCount(count + 1);
    //console.log('Location changed!', location.pathname);
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (country.value.country !== "" || data.uniqueKey !== "") {
          return <Component {...props} />;
        } else if (responseStatus.toString().includes("SyntaxError")) {
          return <Redirect to="https://www.imf.org/external/error.htm" />;
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

class Router extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const currTabPath = this.props.isScenarioEnabled ? "scenario" : "baseline";
    return (
      <Switch>
        <Route exact path="/" component={SelectCountry} />
        <PrivateRoute
          exact
          path="/sdginfopages/index"
          component={OverviewInfoPage}
        />
        <PrivateRoute
          exact
          path="/sdginfopages/user_guide"
          component={UserGuideInfoPage}
        />
        <PrivateRoute
          exact
          path="/sdginfopages/framework"
          component={FrameworkInfoPage}
        />
        <PrivateRoute exact path="/baseline/Overview" component={BaselineOve} />
        {/* finacing tab */}
        <PrivateRoute
          exact
          path={`/${currTabPath}/financing/Overview`}
          component={finOverview}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/financing/revenues`}
          component={FinRevenue}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/financing/Public Expenditure`}
          component={FinSpending}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/financing/Debt Financing`}
          component={DebtFin}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/financing/Private Financing`}
          component={privateFinance}
        />

        {/* costing tab */}

        <PrivateRoute
          exact
          path={`/${currTabPath}/costing/Overview`}
          component={costOverview}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/costing/SDG Cost`}
          component={sdgCost}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/costing/Initial Levels`}
          component={CostingInitials}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/macro/Inflation, Interest and Exchange Rates`}
          component={MacroInFlation}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/macro/GDP`}
          component={MacroGDP}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/macro/External Sector`}
          component={MacroExternalSector}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/advParameter/Physical capital Stocks`}
          component={AdvPhysicalCapital}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/advParameter/Production Function`}
          component={AdvProductionFunction}
        />
        <PrivateRoute
          exact
          path={`/${currTabPath}/advParameter/Overview`}
          component={AdvOverview}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/advParameter/Human Capital`}
          component={AdvHumanCapital}
        />

        <PrivateRoute
          exact
          path={`/${currTabPath}/macro/Overview`}
          component={macOverview}
        />

        {/* <Route exact path="/scenario" component={Overview} /> */}
        <PrivateRoute exact component={Dashboard} path="/dashboard/overview" />

        <PrivateRoute exact component={BaselineOve} path="/scenario/overview" />

        {/* <PrivateRoute exact path="/ExportToPdf"
          component={pdf} target="_blank" rel="noopener noreferrer" /> */}

        {/* if nothing matches then redirect to home */}
        <Redirect to="/" />

        <PrivateRoute path="https://www.imf.org/external/error.htm" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isScenarioEnabled: state.country.value.isScenarioEnabled,
    wasPageLoaded: state.country.value.staticPage,
  };
};

export default connect(mapStateToProps)(Router);
