/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  changeCountry,
  setUniqueKey,
  toggleShowUniqueKey,
  updateScenarioEnabled,
  swapTempState,
  updateDashboardEnabled,
  updateLastUrl,
  updateStaticPage,
  updateStateNotSaved,
  updateOpenWarningMessage,
  updateSaveTabSelection,
  getResponseStatus,
  updateFlagWarningMessage,
  updateRemoveBackgroundImage,
  updateNanWarningMsg,
  updateAllowUserToNavigate,
  updateProgressBar,
  updateMainTabsWarningMsg,
  updateResetWarningMessage,
} from "../store/reducerSlice";
import {
  withStyles,
  Button,
  Box,
  Grid,
  Tabs,
  Tab,
  MenuItem,
  Menu,
  Hidden,
  Toolbar,
  AppBar,
  Typography,
} from "@material-ui/core";
import HeaderMenu from "./HeaderMenu";
import MenuIcon from "../assets/hambrg.svg";
import ScIcon from "../assets/scenario-ko.svg";
import bsLnIcon from "../assets/baseline-ko.svg";
import DsBIcon from "../assets/compare-ko.svg";
import orangeScIcon from "../assets/scenario-or.svg";
import orangeBsLnIcon from "../assets/baseline-or.svg";
import orangeDsBIcon from "../assets/compare-or.svg";
import UniqueKeyPopup from "../baseline/uniqueKeyPopup";
import ResetWarningMessage from "../baseline/resetWarningMessage";

const useStyle = (theme) => ({
  logo: {
    ...theme.mixins.toolbar,
    boxSizing: "border-box",
    borderBottom: "none",
    "font-family": "'MuseoSans 700'",
    fontSize: 16,
    color: "#FFFFFF",
    flex: 1,
    [theme.breakpoints.up(`${"md"}`)]: {
      borderBottom: "1px solid #cccccc",
    },
    "& p": {
      fontStyle: "normal",
      "font-family": "'MuseoSans 700'",
      fontSize: 16,
      "&:last-child": {
        fontSize: 13,
        "font-family": "'MuseoSans 700'",
        textTransform: "uppercase",
        color: "#FFFFFF",
      },
    },
  },
  hdrCntry: {
    ...theme.mixins.toolbar,
    padding: "1.0em",
    boxSizing: "border-box",
    borderBottom: "none",
    color: "#FFFFFF",
    [theme.breakpoints.up(`${"md"}`)]: {
      borderBottom: "1px solid #cccccc",
    },
    "& p": {
      fontSize: 17,
      fontFamily: "Arial black",
      textTransform: "uppercase",
    },
  },
  tabRoot: {
    "& .MuiButtonBase-root": {
      ...theme.mixins.toolbar,
      color: "#FFFFFF",
      "font-family": "'MuseoSans 500'",
      fontSize: 13,
      opacity: 1,
      flex: 1,
      borderLeft: "1px solid #cccccc",
      borderBottom: "1px solid #cccccc",
      padding: "0 20px",
      maxWidth: 166,
      "&.Mui-selected": {
        fontWeight: "bold",
        color: "#E35205",
        borderBottom: "1px solid #ffffff",
        backgroundColor: "#ffffff",
      },
      "& .MuiTab-wrapper": {
        fontWeight: "bold",
        display: "flex",
        "font-family": "'MuseoSans 500'",
        flexDirection: "row",
        justifyContent: "center",
        "&>:first-child": {
          marginRight: 10,
        },
      },
      "&:last-child": {
        borderRight: "1px solid #cccccc",
      },
    },
  },
  hambrg: {
    ...theme.mixins.toolbar,
    borderBottom: "none",
    borderRadius: 0,
    [theme.breakpoints.up(`${"md"}`)]: {
      borderBottom: "1px solid #cccccc",
    },
  },
  uniqueKeyContainer: {
    ...theme.mixins.toolbar,
    borderBottom: "none",
    [theme.breakpoints.up(`${"md"}`)]: {
      borderBottom: "1px solid #cccccc",
    },
  },
  mobileHeader: {
    borderBottom: "1px solid #cccccc",
    [theme.breakpoints.up(`${"md"}`)]: {
      borderBottom: "none",
    },
  },
  uniqueKey: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#ffffff", "!important"],
    "& span": { margin: 0, display: "block" },
    "& span:last-child": {
      fontWeight: "bold",
    },
  },
  headergradient: {
    background: "#004C97",
  },
  paper: {
    left: "10px !important",
    marginTop: 5,
    fontSize: 12,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    "& li.Mui-selected": {
      fontWeight: 700,
    },
    fontSize: 12,
  },
  hamburgerClose: {
    "& span": {
      textAlign: "right",
      padding: "0 15px",
      display: "inline-block",
      float: "right",
      fontSize: 20,
      cursor: "pointer",
      color: "#004C97",
    },
  },
  hamburgerDiv: {
    clear: "both",
  },
});
const StyledMenuItem = withStyles(() => ({
  root: {
    "font-family": "'MuseoSans'",
    fontSize: 14,
    color: "#004C97",
  },
}))(MenuItem);
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.urlLocation = "";
    this.state = {
      value: "",
      baseline: "",
      scenario: "",
      anchorEl: null,
    };
  }

  componentDidMount() {
    let url = this.props.history.location.pathname;
    this.urlLocation = url.split("/");
    this.setState({ value: this.urlLocation[1] || "" });
  }

  componentDidUpdate(prevProps) {
    if (this.props.flagWarningMessage !== prevProps.flagWarningMessage) {
      this.props.onUpdateFlagWarningMessage();
      this.handleChange(this.props.saveTabSelection, true);
    }
  }

  static getDerivedStateFromProps(props) {
    let url = props.history.location.pathname;
    if (url === "/") {
      return {
        value: "",
        baseline: "",
        scenario: "",
      };
    } else {
      return {
        value: url.split("/")[1] || "",
      };
    }
  }

  saveAndContinue = () => {
    this.props.onUpdateStateNotSaved(false);
    this.props.onCloseWarningMessage("save");
  };

  changePage = () => {
    if (this.props.nextPageToNavigate === "/scenario/overview") {
      this.props.onUpdateRoutingTab(true);
    }
    this.props.history.push(
      this.props.nextPageToNavigate === ""
        ? this.props.saveSubTabSelection
        : this.props.nextPageToNavigate
    );
  };

  continueWithoutSaving = () => {
    this.handleChange(this.props.saveTabSelection, true);
    this.props.onCloseWarningMessage("nosave");
  };

  cancelWarningMsg = () => {
    this.props.onCloseWarningMessage("cancel");
    this.props.onAllowUserToNavigate();
    this.props.onUpdateNanWarning();
  };

  cancelResetWarningMsg = () => {
    this.props.onCloseWarningMessage("cancel");
    this.props.onUpdateResetWarningMessage(false);
  };

  handleChange = (newValue) => {
    this.setState({ value: newValue });
    let newUrl = "";
    const url = this.props.isDashboardEnabled
      ? this.props.lastUrl
      : this.props.history.location.pathname;
    newUrl = url.includes("baseline")
      ? url.split("/baseline")
      : url.split("/scenario");
    newUrl.shift();
    if (this.props.stateNotSaved) {
      this.props.onUpdateMainTabsWarningMsg(true);
      this.props.onUpdateWarningMessage(true);
    } else {
      if (newValue === "baseline") {
        if (this.props.isScenarioEnabled) {
          this.props.onResetAllBaselineOrScenario(newValue);
        }

        this.props.switchToScenario(false);
        this.props.swapStateOnTab(newValue);
        this.props.history.push(`/${newValue}${newUrl[0]}`);
        this.props.switchToDashboard(false);
        this.props.onUpdateFlagWarningMessage();
      } else if (newValue === "scenario") {
        this.props.getScenarioData();
        this.props.switchToScenario(true);
        if (this.props.scenarioHasData.country) {
          this.props.swapStateOnTab(newValue);
        }
        this.props.switchToDashboard(false);
        if (window.location.href.includes("costing")) {
          this.props.history.push("/scenario/Overview");
        } else {
          this.props.history.push(`/${newValue}${newUrl[0]}`);
        }
        this.props.onUpdateFlagWarningMessage();
      } else if (newValue === "dashboard") {
        this.props.getDashboardData();
        this.props.switchToDashboard(true);
        this.props.switchToScenario(false);
        this.props.saveUrl(url);
        this.props.history.push(`/${newValue}/overview`);
      }
    }
    this.props.onUpdateSaveTabSelection(newValue);
  };

  baseLine = (url) => {
    this.setState({ baseline: url });
  };

  Scenario = (url) => {
    this.setState({ scenario: url });
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleReset = () => {
    this.props.onResetAllBaselineOrScenario(this.state.value)
  }
  hamburgerSelection = (value) => {
    this.handleMenuClose();

    if (value === "country") {
      this.props.history.push("/");
      this.props.resetCountry("");
      this.props.resetUniqueKey("");
      this.props.resetToggleShowUniqueKey(true);
      this.props.onUpdateBackgroundImage();
      window.location.reload();
    } else if (value === "overview") {
      this.props.resetCountry("");
      this.props.resetUniqueKey("");
      this.props.resetToggleShowUniqueKey(true);
      this.props.history.push("/sdginfopages/index");
    } else if (value === "guide") {
      this.props.resetCountry("");
      this.props.resetUniqueKey("");
      this.props.resetToggleShowUniqueKey(true);
      this.props.history.push("/sdginfopages/user_guide");
    } else if (value === "framework") {
      this.props.resetCountry("");
      this.props.resetUniqueKey("");
      this.props.resetToggleShowUniqueKey(true);
      this.props.history.push("/sdginfopages/framework");
    }
  };

  render() {
    let { value, baseline, scenario } = this.state;
    const { classes } = this.props;

    return (
      <div>
        <AppBar
          elevation={0}
          position="static"
          color={"transparent"}
          className={classes.headergradient}
        >
          <Toolbar disableGutters={true}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.mobileHeader}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                pl={2}
                py={1}
                className={classes.logo}
              >
                <Hidden only={["xs", "sm"]}>
                  <Typography display="inline">
                    INTERNATIONAL MONETARY FUND
                  </Typography>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <Typography display="inline">IMF</Typography>
                </Hidden>
                <Typography display="inline">SDG FINANCING TOOL</Typography>
              </Box>
              <Hidden only={["md", "lg", "xl"]}>
                <Box
                  display="flex"
                  alignItems="center"
                  px={2}
                  py={1}
                  className={classes.uniqueKeyContainer}
                >
                  {this.props.uniqueKey ? (
                    <Typography className={classes.uniqueKey}>
                      <span>{"SDG Unique Key: "}</span>
                      <span>{`${this.props.baselineScenarioData.uniqueKey}`}</span>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              </Hidden>
              <Box display="flex" alignItems="center">
                {value !== "" && (
                  <Hidden only={["xs", "sm"]}>
                    <React.Fragment>
                      <Box className={classes.hdrCntry}>
                        <Typography display="inline">
                          {this.props.country}
                        </Typography>
                      </Box>
                      <Tabs
                        className={classes.tabRoot}
                        value={value}
                        TabIndicatorProps={{ style: { background: "#ffffff" } }}
                      >
                        <Tab
                          label="baseline"
                          onClick={() => this.handleChange("baseline")}
                          value={"baseline"}
                          icon={
                            this.props.routingTab === "baseline" &&
                            !this.props.isDashboardEnabled ? (
                              <img src={orangeBsLnIcon} />
                            ) : (
                              <img src={bsLnIcon} />
                            )
                          }
                        />
                        <Tab
                          label="scenario"
                          onClick={() => this.handleChange("scenario")}
                          value={"scenario"}
                          icon={
                            this.props.isScenarioEnabled &&
                            !this.props.isDashboardEnabled ? (
                              <img src={orangeScIcon} />
                            ) : (
                              <img src={ScIcon} />
                            )
                          }
                        />

                        <Tab
                          label="Comparison Dashboard"
                          onClick={() => {
                            this.handleChange("dashboard");
                          }}
                          //value={"scenario"}
                          value={"dashboard"}
                          icon={
                            this.props.isDashboardEnabled ? (
                              <img src={orangeDsBIcon} />
                            ) : (
                              <img src={DsBIcon} />
                            )
                          }
                        />
                      </Tabs>
                    </React.Fragment>
                  </Hidden>
                )}
                <Button
                  aria-label="headerMenu"
                  className={classes.hambrg}
                  onClick={this.handleMenuClick}
                >
                  <img src={MenuIcon} height={20} width={25} />
                </Button>
                <Menu
                  id="headerMenu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleMenuClose}
                  // PaperProps={{
                  //   style: {
                  //     "& li.MuiMenuItem-root": {
                  //       fontSize: 14
                  //     }
                  //   }
                  // }}
                  elevation={3}
                  getContentAnchorEl={null}
                >
                  <Typography
                    component="div"
                    className={classes.hamburgerClose}
                  >
                    <span onClick={this.handleMenuClose}> &times; </span>
                    <div className={classes.hamburgerDiv}></div>
                  </Typography>
                  <StyledMenuItem
                    onClick={() => this.hamburgerSelection("country")}
                  >
                    Change Country
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => this.hamburgerSelection("overview")}
                  >
                    SDG Financing Tool Overview
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => this.hamburgerSelection("guide")}
                  >
                    User Guide{" "}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => this.hamburgerSelection("framework")}
                  >
                    The Framework{" "}
                  </StyledMenuItem>
                </Menu>
              </Box>
            </Grid>
          </Toolbar>
        </AppBar>
        <div hidden={value !== "baseline"} index={0}>
          <HeaderMenu
            dataSection={"baseline"}
            handleSection={this.handleChange}
            urlHandler={this.baseLine}
            activeTab={value}
            activeURL={baseline}
          />
        </div>
        <div hidden={value !== "scenario"} index={1}>
          <HeaderMenu
            dataSection={"scenario"}
            handleSection={this.handleChange}
            urlHandler={this.Scenario}
            activeTab={value}
            activeURL={scenario}
          />
        </div>
        <div hidden={value !== "dashboard"} index={2}>
          <HeaderMenu
            dataSection={"dashboard"}
            handleSection={this.handleChange}
            urlHandler={this.Scenario}
            activeTab={value}
            activeURL={scenario}
          />
        </div>
        <div>
          {this.props.openWarningMessage || this.props.nanWarningMsg ? (
            <UniqueKeyPopup
              saveWarningMsg={this.props.nanWarningMsg ? false : true}
              redirectToTab={this.props.handleChange}
              handleWarningMsg={this.props.onCloseWarningMessage}
              saveAndContinue={this.saveAndContinue}
              continueWithoutSaving={this.continueWithoutSaving}
              cancelWarningMsg={this.cancelWarningMsg}
              nanWarningMsg={this.props.nanWarningMsg}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {this.props.resetWarningMessage ? (
            <ResetWarningMessage
              cancelResetWarningMsg={this.cancelResetWarningMsg}
              proceedAndReset={this.handleReset}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}

const routerWrapper = withRouter(Header);

const mapStateToProps = (state) => ({
  isScenarioEnabled: state.country.value.isScenarioEnabled,
  isDashboardEnabled: state.country.value.isDashboardEnabled,
  planningYear: state.country.baselineScenarioData.planningYear,
  baselineScenarioData: state.country.baselineScenarioData,
  country: state.country.baselineScenarioData.country,
  uniqueKey: state.country.baselineScenarioData.uniqueKey,
  scenarioHasData: state.country.value.tempScenarioData,
  lastUrl: state.country.value.lastUrl,
  dashboardDataAvailable: state.country.compDashData.dashboardBackupData,
  routingTab: state.country.value.routingTab,
  stateNotSaved: state.country.value.stateNotSaved,
  nanWarningMsg: state.country.value.nanWarningMsg,
  openWarningMessage: state.country.value.openWarningMessage,
  handleWarningSteps: state.country.value.handleWarningSteps,
  saveTabSelection: state.country.value.saveTabSelection,
  saveSubTabSelection: state.country.value.saveSubTabSelection,
  responseStatus: state.country.value.responseStatus,
  flagWarningMessage: state.country.value.flagWarningMessage,
  nextPageToNavigate: state.country.value.nextPageToNavigate,
  mainTabsWarningMsg: state.country.value.mainTabsWarningMsg,
  resetWarningMessage: state.country.value.resetWarningMessage,
});

const mapDispatchToProps = (dispatch) => ({
  switchToScenario: (data) => {
    dispatch(updateScenarioEnabled(data));
  },
  switchToDashboard: (data) => {
    dispatch(updateDashboardEnabled(data));
  },
  onResetAllBaselineOrScenario: (data) => {
    dispatch(updateProgressBar(true));
    dispatch(updateResetWarningMessage(false));
    dispatch(updateOpenWarningMessage(false));
    dispatch({ type: "RESET_ALL_BASELINE" });
    if(data==="baseline"){
      dispatch(updateStateNotSaved(false));
    }else{
      dispatch(updateStateNotSaved(true));
    }
  },
  resetCountry: () => {
    dispatch(changeCountry());
  },
  resetUniqueKey: (uniqueKey) => {
    dispatch(setUniqueKey(uniqueKey));
  },
  getScenarioData: () => {
    dispatch({ type: "GET_SCENARIO_DATA" });
  },
  getDashboardData: () => {
    dispatch({ type: "GET_DASHBOARD_DATA" });
  },
  swapStateOnTab: (data) => {
    dispatch(swapTempState(data));
  },
  resetToggleShowUniqueKey: (showUniqueKey) => {
    dispatch(toggleShowUniqueKey(showUniqueKey));
  },
  saveUrl: (data) => {
    dispatch(updateLastUrl(data));
  },
  onUpdateStaticPage: (data) => {
    dispatch(updateStaticPage(data));
  },
  onUpdateStateNotSaved: (data) => {
    dispatch(updateStateNotSaved(data));
  },
  onUpdateWarningMessage: (data) => {
    dispatch(updateOpenWarningMessage(data));
  },
  onCloseWarningMessage: (data) => {
    dispatch(updateOpenWarningMessage(false));
    if (data === "nosave") {
      dispatch(updateStateNotSaved(false));
    } else if (data === "save") {
      dispatch(updateProgressBar(true));
      dispatch(updateOpenWarningMessage(false));
      dispatch(updateStateNotSaved(false));
      dispatch({ type: "GET_SAVE_DATA" });
    }
  },
  onUpdateSaveTabSelection: (data) => {
    dispatch(updateSaveTabSelection(data));
  },
  onUpdateResponseStatus: () => {
    dispatch(getResponseStatus(""));
  },
  onUpdateFlagWarningMessage: () => {
    dispatch(updateFlagWarningMessage(""));
  },
  onUpdateBackgroundImage: () => {
    dispatch(updateRemoveBackgroundImage(false));
  },
  onUpdateNanWarning: () => {
    dispatch(updateNanWarningMsg(false));
  },
  onAllowUserToNavigate: () => {
    dispatch(updateAllowUserToNavigate(true));
  },
  onUpdateRoutingTab: (data) => {
    dispatch(updateScenarioEnabled(data));
  },
  onUpdateMainTabsWarningMsg: (data) => {
    dispatch(updateMainTabsWarningMsg(data));
  },
  onUpdateResetWarningMessage: (data) => {
    dispatch(updateResetWarningMessage(data));
  },
});

const headerRouterWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(routerWrapper);

export default withStyles(useStyle)(headerRouterWrapper);
