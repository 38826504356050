/* eslint-disable */
export default function AdobeTracker(){
    const URL = window.location.href;
    const s_account = window.location.hostname.split(".")[0] !== "sdgfit" ?
    "imfsdgfitnonprod" : "imfsdgfitprod";
    const s = s_gi(s_account);
    s.pageName = URL; 
    s.pageURL = URL;
    s.trackingServer = "swebstats.imf.org";
    s.t();
}
