const xlRows = {
    Columns: [
      {
        col: "A1:C1",
        fml: "=isblank(A1)=false",
        bold: true,
        bg: "00000000",
        fname: "Calibri",
        fsize: 11,
        fcolor: "FFFFFFFF"
      },
      {
        col: "A6:AH6",
        fml: "=AND($A6:$AH6)",
        bold: true,
        bg: "00000000",
        fname: "Calibri",
        fsize: 11,
        fcolor: "FFFFFFFF"
      },
      {
        col: "A13:AH13",
        fml: "=AND($A13, $AH13)",
        bold: true,
        bg: "00000000",
        fname: "Calibri",
        fsize: 11,
        fcolor: "FFFFFFFF"
      },
      {
        col: "A28:AH28",
        fml: "=AND($A28, $AH28)",
        bold: true,
        bg: "00000000",
        fname: "Calibri",
        fsize: 11,
        fcolor: "FFFFFFFF"
      },
      {
        col: "B2:C2",
        fml: "=AND($B2, $C2)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B4:C4",
        fml: "=AND($B4, $C4)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B7:AH7",
        fml: "=AND($B7, $AH7)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B9:AH9",
        fml: "=AND($B9, $AH9)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B11:AH11",
        fml: "=AND($B11, $AH11)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B14:AH14",
        fml: "=AND($B14, $AH14)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B16:AH16",
        fml: "=AND($B16, $AH16)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B18:AH18",
        fml: "=AND($B18, $AH18)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B20:AH20",
        fml: "=AND($B20, $AH20)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B22:AH22",
        fml: "=AND($B22, $AH22)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B24:AH24",
        fml: "=AND($B24, $AH24)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B26:AH26",
        fml: "=AND($B26, $AH26)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B29:AH29",
        fml: "=AND($B29, $AH29)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B31:AH31",
        fml: "=AND($B31, $AH31)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
      {
        col: "B33:AH33",
        fml: "=AND($B33, $AH33)",
        bold: false,
        bg: "DDDDDDDD",
        fname: "Calibri",
        fsize: 11,
        fcolor: "00000000"
      },
    ]
  };

  const data = [
    {
      col1: "SDG needs met by",
      col2: "Baseline",
    //   col3: xlData.dashboardBackupData.data.baseline.yearToAchieve,
    },
    {
      col1: "",
      col2: "Scenario",
    //   col3: xlData.dashboardBackupData.data.scenario.yearToAchieve,
    },
    {
      col1: "Additional financing needed per year (percent of GDP)",
      col2: "Baseline",
    //   col3: xlData.dashboardBackupData.data.baseline
        // .additionalFinancingNeeds,
    },
    {
      col1: "",
      col2: "Scenario",
    //   col3: xlData.dashboardBackupData.data.scenario
        // .additionalFinancingNeeds,
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ];

  const srcCells = {
    A1: "C1",
    A2: "A3",
    A4: "A5",
    A6: "B6",
    A7: "A8",
    A9: "A10",
    A11: "A12",
    A13: "B13",
    A14: "A15",
    A16: "A17",
    A18: "A19",
    A20: "A21",
    A22: "A23",
    A24: "A25",
    A26: "A27",
    A28: "B28",
    A29: "A30",
    A31: "A32",
    A33: "A34",
  };

  const allBorder = [2, 4, 7, 9, 11, 14, 16, 18, 20, 22, 24, 26, 29, 31, 33];
      const lrBorder = [7, 9, 11, 14, 16, 18, 20, 22, 24, 26, 29, 31, 33];
      const lbrBorder = [8, 10, 12, 15, 17, 19, 21, 23, 25, 27, 30, 32, 34];
      const bBorder = [8, 10, 15, 17, 19, 21, 23, 25, 27, 30, 32, 34];
      const rBorder = [7, 9, 11, 14, 16, 18, 20, 22, 24, 26, 29, 31, 33];
      const brBorder = [8, 10, 12, 15, 17, 19, 21, 23, 25, 27, 30, 32, 34];
      const dataRows = [
        7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
        27, 29, 30, 31, 32, 33, 34,
      ];
  export {xlRows,data,srcCells,allBorder,lrBorder,
    lbrBorder,bBorder,rBorder,brBorder,dataRows};