import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const NextButton = withStyles({
  root: {
    color: "white",
  },
  label:{
    color: "#FFFFFF",
    whiteSpace: "nowrap"
  },
  contained:{
    borderColor: "#004C97",
    backgroundColor: "#004C97",
    "&:hover": {
      // css for hover
      backgroundColor: "#004C97"
    },
  },
  disabled:{
    background: "rgba(0, 76, 151,.5) !important"
  }
})(Button);

const LandingNextBtn = withStyles({
  root: {
    color: "white",
  },
  label:{
    "font-family": "'MuseoSans 700'",
    fontSize: [12, "!important"],
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#009CDE"
    },
    whiteSpace: "nowrap"
  },
  contained:{
    borderColor: "#004C97",
    backgroundColor: "#009CDE",
    "&:hover": {
      backgroundColor: "#009CDE"
    },
  },
  disabled:{
    background: "#004C97"
  }
})(Button);



export default function CustomNextButton ({ fromLandingpage, ...props}) {
  
  if(fromLandingpage){
    return (
      <LandingNextBtn
        {...props}
    />
    );
  } else {
    return (
      <NextButton
        {...props}
    />
    );
  }
}


