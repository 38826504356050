import React, { useEffect } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStore } from "./store/reducerSlice";
import Router from "./route/route";
import Header from "./Header/header";
import Footer from "./Footer/footer";
import { useTranslation } from "react-i18next";
import { appStyles } from "./css/custom";
import backImage from "./assets/landingpage/landing-back.png";
import AdobeTracker from "./utils/adobeTracker";

function App() {
  const { i18n } = useTranslation();
  const classes = appStyles();
  const store = useSelector(getStore);
  const landingProgressBar = store.value.landingProgressBar;
  const footerProgressBar = store.value.progressBar;
  const removeBackgroundImage = store.value.removeBackgroundImage;

  useEffect(() => {
    document.dir = i18n.dir();
    AdobeTracker();
  }, [i18n, i18n.language]);

  return (
    <div>
      <BrowserRouter>
        <Header />
        {landingProgressBar ? <LinearProgress color="primary" /> : <></>}
        <Grid
          className={classes.appPage}
          style={{
            backgroundImage: !removeBackgroundImage ? `url(${backImage})` : "",
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            direction="row"
          >
            <Router />
          </Grid>
        </Grid>
        {footerProgressBar ? <LinearProgress color="primary" /> : <></>}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
