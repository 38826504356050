export function mapCountryData(data = []) {
    if (!data || !data.length) return [];
    return data.map((item) => ({
            CountryID: item.countryCode,
            CountryName: item.countryName,
            CountryShortName: item.countryCode3,
            CountryCode: item.countryCode,
            UNCode: item.countryUNCode,
            IncomeCategory: item.incomeCategory,
            Active: "Y",
    }));
  }

  export function mapYearData(data = []) {
    if (!data || !data.length) return [];
    return data.map((item) => (item.year_Name));
  }