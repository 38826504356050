import React from "react";
import { Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";

export default function FillIndicators({
  myIndicator = [],
  isHeading = false,
  lg = 2,
  md = 2,
  sm = 2,
  xs = 2,
  gridCustomClass = "",
  typoCustomClass = "",
  baseDashIndicator = [],
  indicatorDescription = "",
}) {
  const store = useSelector(getStore);
  const dashboardEnabled = store.value.isDashboardEnabled;
  const customBase = {
    "fontFamily": "'MuseoSans 500'",
    fontSize: [14, "!important"],
    color: "#78BE20",
  };
  const customScen = {
    "fontFamily": "'MuseoSans 500'",
    fontSize: [14, "!important"],
    color: "#009CDE",
  };

  function elementToDisplay(indicator, index) {
    let displayBaseValue = "";
    let displayScenValue = "";
    if (indicatorDescription === "GDPPerCapita") {
      displayBaseValue = indicator;
      displayScenValue = baseDashIndicator[index];
    } else {
      displayBaseValue = Number(indicator || "").toFixed(1);
      displayScenValue = Number(baseDashIndicator[index] || "").toFixed(1);
    }

    if (isHeading && !dashboardEnabled) {
      return <Typography sx={typoCustomClass}>{indicator}</Typography>;
    } else if (isHeading && dashboardEnabled) {
      return (
        <>
          <Typography sx={typoCustomClass}>{indicator}</Typography>
          <span style={customBase}>{"BL"}</span>
          {" | "}
          <span style={customScen}>{"SC"}</span>
        </>
      );
    } else if (dashboardEnabled) {
      return (
        <>
          <span style={customBase}>{displayBaseValue}</span>
          {" | "}
          <span style={customScen}>{displayScenValue}</span>
        </>
      );
    } else {
      return <Typography sx={typoCustomClass}>{displayBaseValue}</Typography>;
    }
  }

  return myIndicator.map((indicator, index) => {
    return (
      <Grid
        item
        lg={lg}
        md={md}
        sm={sm}
        xs={xs}
        sx={gridCustomClass}
        key={index}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {elementToDisplay(indicator, index)}
      </Grid>
    );
  });
}
