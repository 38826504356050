import { createSlice, current } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  value: {
    yearList: [],
    revEndYearArr: [],
    countryList: [],
    revStartYearArr: [],
    nanWarningMsg: false,
    progressBar: false,
    showUniqueKey: true,
    changeCountry: false,
    stateNotSaved: false,
    tempBaselineData: {},
    tempScenarioData: {},
    scenRevenueYearArr: [],
    projectionYearList: [],
    isScenarioEnabled: false,
    isDashboardEnabled: false,
    openWarningMessage: false,
    resetWarningMessage: false,
    landingProgressBar: false,
    tempBaselineDataToSend: {},
    tempScenarioDataToSend: {},
    saveResetItemNamePath: {},
    removeBackgroundImage: false,
    savedFromNavigationBtn: false,
    allowUserToNavitgate: true,
    lastUrl: "",
    year: "2030",
    staticPage: "",
    responseStatus: "",
    saveTabSelection: "",
    nextSubPageToNav: "",
    nextPageToNavigate: "",
    saveSubTabSelection: "",
    routingTab: "baseline",
    flagWarningMessage: "",
    handleWarningSteps: "",
    selectedFinanceYear: "",
    radioSelection: "modelbased",
    mainTabsWarningMsg: false,
  },
  baselineScenarioData: {
    country: "",
    uniqueKey: "",
    planningYear: "",
    nanFound: "",
    financingNeeds: 0.0,
    modelBasedRer: false,
    exogenousNer: true,
    baselineReset: true,
    settingsReset: false,
    settingsResetValues: "",
    projectionPeriods: [],
    settings: {
      financing: {
        revenues: {
          taxRevenueSettings: {
            taxReform: false,
            start: 2023,
            end: 2050,
            improvementTaxRevenue: 0,
          },
          taxRevenues: [],
          nonTaxRevenues: [],
          projectedGrants: [],
          revenuesAndGrants: [],
        },
        debtFinancing: {
          overallFiscalBalance: [],
          newNetBorrowing: [],
          residentsNetBorrowing: [],
          nonResidentsNetBorrowing: [],
          domesticPublicDebtENir: [],
          externalPublicDebtENir: [],
          stockFlowAdjustments: [],
        },
        publicExpenditure: {
          shareSdgSpendingForInfrastructure: 0.0,
          requiredPrivateInfrastructureFinancingSubsidies: [],
          educationAndHealthSpendingEfficiencyParameter: [],
          infrastructureSpendingEfficiencyParameter: [],
          nonSdgCurrentPrimarySpending: [],
          nonSdgCapitalSpending: [],
        },
        privateFinancing: {
          privateSpendingOnHealthAndEducation: [],
          privateInvestmentInSdgInfrastructure: [],
        },
        externalGrants: {
          projectedGrants: [],
        },
      },
      costing: {
        sdgCost: {
          health: 0.0,
          education: 0.0,
          electricity: 0.0,
          waterAndSanitation: 0.0,
          roads: 0.0,
          total: 0.0,
        },
        initialLevels: {
          health: 0.0,
          education: 0.0,
          infrastructure: 0.0,
          bankableInfrastructure: 0.0,
          nonBankableInfrastructure: 0.0,
          total: 0.0,
        },
      },
      macro: {
        gdp: {
          projectionApproach: {
            calibrate: false,
            exogenousRealGdpGrowthRate: [],
            tfpGrowthRate: [],
            tfpg: [],
          },
          inputFactorsDynamics: {
            laborForceParticipation: [],
            privateSavingsRate: [],
          },
        },
        externalSector: {
          balanceOfPaymentsFlows: {
            currentAccountBalance: [],
            tradeBalance: [],
            dividendsAndInterest: [],
            publicGrants: [],
            receivedRemittances: [],
            capitalAndFinancialAccountBalance: [],
            foreignDirectInvestment: [],
            publicSectorNetBorrowing: [],
            privateSectorBorrowing: [],
          },
        },
        inflationInterestAndExchangeRates: {
          inflationRates: {
            domesticCpiInflation: [],
            foreignCpiInflation: [],
          },
          nominalInterestRates: {
            externalPrivateDebtNir: [],
            fdiLiabilitiesDividendYield: [],
          },
          exchangeRates: {
            rerElasticity: 0.0,
            ner: [],
          },
        },
      },
      advanced: {
        productionFunction: {
          alphaPrivateCapitalOutputElasticity: 0.0,
          betaPublicCapitalExponent: 0.0,
          thetaPrivatelyManagedInfrastructureEfficiencyGain: 0.0,
          sigmaEducationRelatedLaborProductivityElasticity: 0.0,
        },
        physicalCapitalStocks: {
          publicCapitalDepreciation: 0.0,
          privateCapitalDepreciation: 0.0,
        },
        humanCapital: {
          humanCapitalDepreciation: 0.0,
          humanCapitalDiffusionParameter: 0.0,
          educationSpendingRelatedNewHumanCapitalElasticity: 0.0,
          studentsRelatedNewHumanCapitalElasticity: 0.0,
        },
      },
    },
    indicators: {
      overview: {
        macroEconomicIndicators: {
          perCapitaGdp: [],
          realGdpGrowth: [],
          currentAccountBalance: [],
        },
        governmentFinances: {
          revenuesAndGrants: {
            revenuesNGrants: [],
            additionalFinancingNeeds: [],
          },
          primaryExpenditure: {
            primaryXpenditure: [],
            sdgRelated: [],
            other: [],
          },
          interestExpenditure: [],
          overallBalance: [],
          generalGovernmentDebt: [],
        },
      },
      chartsData: {
        sourcesAddPubSdgFinancing: {},
        revenuesGdp: {},
        overallFiscalBalAndDebt: {},
        nonSdgSpending: {},
        privateSpendHealthEdInfra: {},
        sdgTargets: [],
        initialLevels: [],
        gdpGrowthTrend: [],
        fdiAndRemittance: {},
        domesticAndForeignInflation: {},
        resourcesForAddSdgSpending: {},
      },
    },
  },
  baseScenDataToSend: {},
  compDashData: {
    baselineMacro: {},
    scenarioMacro: {},
    baselineGovFin: {},
    scenarioGovFin: {},
    scenarioChartData: {},
    baselineChartsData: {},
    dashboardBackupData: {},
  },
};

export const ReducerSlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    getResponseStatus: (state, action) => {
      state.value.responseStatus = action.payload;
    },
    selectedCountry: (state, action) => {
      state.baselineScenarioData.country = action.payload;
    },
    updateProgressBar: (state, action) => {
      state.value.progressBar = action.payload;
    },
    updateLandingProgressBar: (state, action) => {
      state.value.landingProgressBar = action.payload;
    },
    updateRemoveBackgroundImage: (state, action) => {
      state.value.removeBackgroundImage = action.payload;
    },
    getSDGBaselineScenarioData: (state, action) => {
      state.baselineScenarioData = action.payload;

      /* if (!state.baseScenDataToSend.country) {
        state.baseScenDataToSend = JSON.parse(JSON.stringify(action.payload));
      } */
      state.baseScenDataToSend = JSON.parse(JSON.stringify(action.payload));

      state.baselineScenarioData.planningYear = String(
        action.payload.planningYear
      );
      state.value.selectedFinanceYear = String(action.payload.planningYear);

      state.value.nanWarningMsg = action.payload.nanFound;

      state.baselineScenarioData.projectionPeriods =
        action.payload.projectionPeriods.map(String) || [];
      if (action.payload.projectionPeriods.length > 1) {
        const maxYears =
          action.payload.projectionPeriods.at(-1) -
          action.payload.projectionPeriods[0];

        state.value.projectionYearList = Array.apply(null, Array(maxYears - 5))
          .map(function (_, i) {
            return parseInt(action.payload.projectionPeriods[0]) + 6 + i;
          })
          .map(String);

        const currentYear = new Date().getFullYear();
        const rangeOfYears = (start, end) =>
          Array(end - start + 1)
            .fill(start)
            .map((year, index) => String(year + index));
        state.value.scenRevenueYearArr = rangeOfYears(
          currentYear,
          currentYear + 30
        );

        state.value.revStartYearArr = state.value.scenRevenueYearArr;
        state.value.revEndYearArr = state.value.scenRevenueYearArr;

        state.value.yearList = Array.apply(null, Array(maxYears + 1))
          .map(function (_, i) {
            return parseInt(action.payload.projectionPeriods[0]) + i;
          })
          .map(String);
      }

      state.baselineScenarioData.
      indicators.overview.macroEconomicIndicators.perCapitaGdp =
        action.payload.indicators.
        overview.macroEconomicIndicators.perCapitaGdp.map(
          (item) => Math.round(item)
        );

      state.baselineScenarioData.settings.financing.revenues = limitDecimalsObj(
        action.payload.settings.financing.revenues
      );

      state.baselineScenarioData.settings.financing.debtFinancing =
        limitDecimalsObj(action.payload.settings.financing.debtFinancing);

      state.baselineScenarioData.settings.financing.publicExpenditure =
        limitDecimalsObj(action.payload.settings.financing.publicExpenditure);

      state.baselineScenarioData.settings.financing.privateFinancing =
        limitDecimalsObj(action.payload.settings.financing.privateFinancing);

      state.baselineScenarioData.settings.financing.externalGrants =
        limitDecimalsObj(action.payload.settings.financing.externalGrants);

      state.baselineScenarioData.settings.costing.sdgCost = limitDecimalsObj(
        action.payload.settings.costing.sdgCost,
        true
      );

      state.baselineScenarioData.settings.costing.initialLevels =
        limitDecimalsObj(action.payload.settings.costing.initialLevels, true);

      state.baselineScenarioData.settings.macro.gdp.projectionApproach =
        limitDecimalsObj(action.payload.settings.macro.gdp.projectionApproach);

      state.baselineScenarioData.settings.macro.gdp.inputFactorsDynamics =
        limitDecimalsObj(
          action.payload.settings.macro.gdp.inputFactorsDynamics
        );

      state.baselineScenarioData.settings.
      macro.externalSector.balanceOfPaymentsFlows =
        limitDecimalsObj(
          action.payload.settings.macro.externalSector.balanceOfPaymentsFlows
        );

      state.baselineScenarioData.settings.
      macro.inflationInterestAndExchangeRates.inflationRates =
        limitDecimalsObj(
          action.payload.settings.macro.inflationInterestAndExchangeRates
            .inflationRates
        );

      state.baselineScenarioData.settings.
      macro.inflationInterestAndExchangeRates.nominalInterestRates =
        limitDecimalsObj(
          action.payload.settings.macro.inflationInterestAndExchangeRates
            .nominalInterestRates
        );

      state.baselineScenarioData.settings.
      macro.inflationInterestAndExchangeRates.exchangeRates =
        limitDecimalsObj(
          action.payload.settings.macro.inflationInterestAndExchangeRates
            .exchangeRates
        );

      if (action.payload.exogenousNer) {
        state.value.radioSelection = "exogenous";
      } else {
        state.value.radioSelection = "modelbased";
      }

      //--------- Production Function Slider % ----------

      state.baselineScenarioData.settings.
      advanced.productionFunction.
      thetaPrivatelyManagedInfrastructureEfficiencyGain =
        Math.round(
          action.payload.settings.advanced.productionFunction
            .thetaPrivatelyManagedInfrastructureEfficiencyGain * 100
        );

      state.baselineScenarioData.settings.
      advanced.productionFunction.
      sigmaEducationRelatedLaborProductivityElasticity =
        Math.round(
          action.payload.settings.advanced.productionFunction
            .sigmaEducationRelatedLaborProductivityElasticity * 100
        );

      //--------- Physical Capital Stocks Slider % ----------

      state.baselineScenarioData.settings.
      advanced.physicalCapitalStocks.publicCapitalDepreciation =
        Math.round(
          action.payload.settings.advanced.physicalCapitalStocks
            .publicCapitalDepreciation * 100
        );

      state.baselineScenarioData.settings.advanced.
      physicalCapitalStocks.privateCapitalDepreciation =
        Math.round(
          action.payload.settings.advanced.physicalCapitalStocks
            .privateCapitalDepreciation * 100
        );

      //-------------- Human Capital Slider % --------------

      state.baselineScenarioData.settings.advanced.
      humanCapital.humanCapitalDepreciation =
        Math.round(
          action.payload.settings.advanced.humanCapital
            .humanCapitalDepreciation * 100
        );
      state.baselineScenarioData.settings.advanced.
      humanCapital.humanCapitalDiffusionParameter =
        Math.round(
          action.payload.settings.advanced.humanCapital
            .humanCapitalDiffusionParameter * 100
        );
      state.baselineScenarioData.settings.advanced.
      humanCapital.educationSpendingRelatedNewHumanCapitalElasticity =
        Math.round(
          action.payload.settings.advanced.humanCapital
            .educationSpendingRelatedNewHumanCapitalElasticity * 100
        );
      state.baselineScenarioData.settings.advanced.
      humanCapital.studentsRelatedNewHumanCapitalElasticity =
        Math.round(
          action.payload.settings.advanced.humanCapital
            .studentsRelatedNewHumanCapitalElasticity * 100
        );

      // Saving previous state to keep it for Scenario change
      if (!state.value.isScenarioEnabled) {
        state.value.tempBaselineData = state.baselineScenarioData;
        state.value.tempBaselineDataToSend = JSON.parse(
          JSON.stringify(action.payload)
        );
      } else {
        state.value.tempScenarioData = state.baselineScenarioData;
        state.value.tempScenarioDataToSend = JSON.parse(
          JSON.stringify(action.payload)
        );
      }
      state.value.progressBar = false;
      state.value.landingProgressBar = false;
      state.value.removeBackgroundImage = true;
    },

    resetSingleItemFromState: (state, action) => {
      const routePath = state.value.saveResetItemNamePath.routePath;
      const itemName = state.value.saveResetItemNamePath.itemName;
      const extractReponseValue = routePath.split(".").reduce((obj, item) => {
        return obj && obj[item];
      }, action.payload);

      const extractCurrentValue = routePath.split(".").reduce((obj, item) => {
        return obj && obj[item];
      }, current(state).baselineScenarioData);

      state.value.stateNotSaved =
        JSON.stringify(extractReponseValue) !==
        JSON.stringify(extractCurrentValue);

      if (
        routePath.includes("advanced") ||
        routePath.includes("shareSdgSpendingForInfrastructure")
      ) {
        sharedSliderInfoFormatter(state, itemName, extractReponseValue, true);
      } else {
        const valueToInsert = Array.isArray(extractReponseValue)
          ? extractReponseValue.map((item) => item.toFixed(2))
          : extractReponseValue;

        const stateToShowHasReset = _.update(
          state.baselineScenarioData,
          routePath,
          () => {
            return valueToInsert;
          }
        );
        const stateToSendHasReset = _.update(
          state.baseScenDataToSend,
          routePath,
          () => {
            return valueToInsert;
          }
        );

        state.baselineScenarioData = stateToShowHasReset;
        state.baseScenDataToSend = stateToSendHasReset;
      }
      state.value.progressBar = false;
    },

    swapTempState: (state, action) => {
      if (action.payload === "baseline") {
        state.baselineScenarioData = state.value.tempBaselineData;
      } else if (action.payload === "scenario") {
        state.baselineScenarioData = state.value.tempScenarioData;
        state.baseScenDataToSend = state.value.tempScenarioDataToSend;
      }
    },
    getSavedData: (state, action) => {
      state.baselineScenarioData = action.payload;
    },
    updateStateNotSaved: (state, action) => {
      state.value.stateNotSaved = action.payload;
      state.value.allowUserToNavitgate = false;
    },
    updateHandleWarningSave: (state, action) => {
      state.value.handleWarningSteps = action.payload;
    },
    updateSaveTabSelection: (state, action) => {
      state.value.saveTabSelection = action.payload;
    },
    updateSubTabSelection: (state, action) => {
      state.value.saveSubTabSelection = action.payload;
    },
    updateOpenWarningMessage: (state, action) => {
      state.value.openWarningMessage = action.payload;
    },
    updateResetWarningMessage: (state, action) => {
      state.value.resetWarningMessage = action.payload;
    },
    updateSliderInfo: (state, action) => {
      const sliderValue = action.payload.sliderValue;
      const itemName = action.payload.itemName;
      sharedSliderInfoFormatter(state, itemName, sliderValue, false);
    },
    getScenarioDashboardData: (state, action) => {
      state.compDashData.dashboardBackupData = action.payload;
      const baseline = action.payload.data.baseline;
      const scenario = action.payload.data.scenario;
      state.compDashData.baselineGovFin =
        baseline.indicators.macroDashboard.governmentFinances;
      state.compDashData.baselineChartsData = baseline.chartsData;
      state.compDashData.scenarioGovFin =
        scenario.indicators.macroDashboard.governmentFinances;
      state.compDashData.baselineMacro =
        baseline.indicators.macroDashboard.macroEconomicIndicators;
      state.compDashData.scenarioMacro =
        scenario.indicators.macroDashboard.macroEconomicIndicators;
      state.compDashData.scenarioChartData = scenario.chartsData;

      state.compDashData.baseMacroGDP =
        baseline.indicators.macroDashboard.
        macroEconomicIndicators.perCapitaGdp.map(
          (item) => Math.round(item)
        );
      state.compDashData.scenMacroGDP =
        scenario.indicators.macroDashboard.
        macroEconomicIndicators.perCapitaGdp.map(
          (item) => Math.round(item)
        );
    },
    updateRevenues: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.financing.revenues =
          limitDecimalsObj(
            state.baselineScenarioData.settings.financing.revenues
          );
      } else {
        state.baselineScenarioData.settings.financing.revenues[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.financing.revenues[
          action.payload.itemName
        ] = action.payload.indicatorArrToSend;
      }
    },
    selectedPlanningYear: (state, action) => {
      state.baselineScenarioData.planningYear = action.payload;
      state.baseScenDataToSend.planningYear = action.payload;
    },
    updatePrivateFinancingData: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.financing.privateFinancing =
          limitDecimalsObj(
            state.baselineScenarioData.settings.financing.privateFinancing
          );
      } else {
        state.baselineScenarioData.settings.financing.privateFinancing[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.financing.privateFinancing[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    updateGDPCalibrate: (state, action) => {
      state.baselineScenarioData.settings.
      macro.gdp.projectionApproach.calibrate =
        action.payload;
      state.baseScenDataToSend.settings.macro.gdp.projectionApproach.calibrate =
        action.payload;
    },
    selectCountry: (state, action) => {
      state.value.country = action.payload;
    },
    updateSDGKey: (state, action) => {
      state.value.changeCountry = false;
      state.baselineScenarioData.uniqueKey = action.payload;
      state.baseScenDataToSend.uniqueKey = action.payload;
    },
    selectYear: (state, action) => {
      state.value.year = action.payload;
    },
    toggleShowUniqueKey: (state, action) => {
      state.value.showUniqueKey = action.payload;
    },
    addCountryList: (state, action) => {
      state.value.countryList = action.payload;
    },
    addYearList: (state, action) => {
      state.value.yearList = action.payload;
    },
    updateMacroGDP: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.macro.gdp.projectionApproach =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro.gdp.projectionApproach
          );
        state.baselineScenarioData.settings.macro.gdp.inputFactorsDynamics =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro.gdp.inputFactorsDynamics
          );
      } else if (action.payload.itemName === "exogenousRealGdpGrowthRate") {
        state.baselineScenarioData.settings.macro.gdp.projectionApproach[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.macro.gdp.projectionApproach[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      } else {
        state.baselineScenarioData.settings.macro.gdp.inputFactorsDynamics[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.macro.gdp.inputFactorsDynamics[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    updateMacroExternal: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.
        macro.externalSector.balanceOfPaymentsFlows =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro.externalSector
              .balanceOfPaymentsFlows
          );
      } else {
        state.baselineScenarioData.settings.
        macro.externalSector.balanceOfPaymentsFlows[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.
        macro.externalSector.balanceOfPaymentsFlows[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    updateMacroInflaton: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.inflationRates =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro
              .inflationInterestAndExchangeRates.inflationRates
          );
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.nominalInterestRates =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro
              .inflationInterestAndExchangeRates.nominalInterestRates
          );
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.exchangeRates =
          limitDecimalsObj(
            state.baselineScenarioData.settings.macro
              .inflationInterestAndExchangeRates.exchangeRates
          );
      } else if (
        action.payload.itemName === "domesticCpiInflation" ||
        action.payload.itemName === "foreignCpiInflation"
      ) {
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.inflationRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.
        macro.inflationInterestAndExchangeRates.inflationRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      } else if (
        action.payload.itemName === "externalPrivateDebtNir" ||
        action.payload.itemName === "fdiLiabilitiesDividendYield"
      ) {
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.nominalInterestRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.
        macro.inflationInterestAndExchangeRates.nominalInterestRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      } else if (action.payload.itemName === "ner") {
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.exchangeRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.
        macro.inflationInterestAndExchangeRates.exchangeRates[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      } else if (action.payload.itemName === "rerElasticity") {
        state.baselineScenarioData.settings.
        macro.inflationInterestAndExchangeRates.exchangeRates[
          action.payload.itemName
        ] = action.payload.value;
        state.baseScenDataToSend.settings.
        macro.inflationInterestAndExchangeRates.exchangeRates[
          action.payload.itemName
        ] = action.payload.value;
      }
    },
    updateExchangeRateRadio: (state, action) => {
      if (action.payload.itemName === "exogenous") {
        state.baselineScenarioData.exogenousNer = true;
        state.baselineScenarioData.modelBasedRer = false;

        state.baseScenDataToSend.exogenousNer = true;
        state.baseScenDataToSend.modelBasedRer = false;

        state.value.radioSelection = "exogenous";
      } else {
        state.baselineScenarioData.modelBasedRer = true;
        state.baselineScenarioData.exogenousNer = false;

        state.baseScenDataToSend.modelBasedRer = true;
        state.baseScenDataToSend.exogenousNer = false;

        state.value.radioSelection = "modelbased";
      }
    },
    updateAdvanceProdFunc: (state, action) => {
      if (action.payload.itemName === "exogenousRealGdpGrowthRate") {
        state.baselineScenarioData.settings.macro.gdp.projectionApproach[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      } else {
        state.baselineScenarioData.settings.macro.gdp.inputFactorsDynamics[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.macro.gdp.inputFactorsDynamics[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    changeCountry: (state) => {
      state.baselineScenarioData.country = "";
      state.value.changeCountry = true;
    },
    addAdvPhyCap: (state, action) => {
      state.value.advPhyCap = action.payload;
    },
    addAdvHumCap: (state, action) => {
      state.value.advHumCap = action.payload;
    },
    addInitLevels: (state, action) => {
      state.value.costInitLvls = action.payload;
    },
    updateSDGCost: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.costing.sdgCost = limitDecimalsObj(
          state.baselineScenarioData.settings.costing.sdgCost,
          true
        );
      } else {
        state.baselineScenarioData.settings.costing.sdgCost[
          action.payload.itemName
        ] = action.payload.value;
        state.baseScenDataToSend.settings.costing.sdgCost[
          action.payload.itemName
        ] = action.payload.value;
      }
    },
    updateSDGInitialLevels: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.costing.initialLevels =
          limitDecimalsObj(
            state.baselineScenarioData.settings.costing.initialLevels,
            true
          );
      } else {
        state.baselineScenarioData.settings.costing.initialLevels[
          action.payload.itemName
        ] = action.payload.value;
        state.baseScenDataToSend.settings.costing.initialLevels[
          action.payload.itemName
        ] = action.payload.value;
      }
    },
    updateDebtFinancing: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.financing.debtFinancing =
          limitDecimalsObj(
            state.baselineScenarioData.settings.financing.debtFinancing
          );
      } else {
        state.baselineScenarioData.settings.financing.debtFinancing[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.financing.debtFinancing[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    addRevenue: (state, action) => {
      state.value.revenue = action.payload;
    },
    updateSpending: (state, action) => {
      if (action.payload.hasSaved) {
        state.baselineScenarioData.settings.financing.publicExpenditure =
          limitDecimalsObj(
            state.baselineScenarioData.settings.financing.publicExpenditure
          );
      } else {
        state.baselineScenarioData.settings.financing.publicExpenditure[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
        state.baseScenDataToSend.settings.financing.publicExpenditure[
          action.payload.itemName
        ] = action.payload.indicatorArrToStore;
      }
    },
    addChartData: (state, action) => {
      state.value.chartData = action.payload;
    },
    setUniqueKey: (state, action) => {
      state.value.uniqueKey = action.payload;
    },
    updateScenarioEnabled: (state, action) => {
      state.value.isScenarioEnabled = action.payload;
      state.value.routingTab = action.payload ? "scenario" : "baseline";
    },
    updateDashboardEnabled: (state, action) => {
      state.value.isDashboardEnabled = action.payload;
    },
    updateRevenueTaxReform: (state, action) => {
      state.baselineScenarioData.settings.
      financing.revenues.taxRevenueSettings.taxReform =
        action.payload;
      state.baseScenDataToSend.settings.
      financing.revenues.taxRevenueSettings.taxReform =
        action.payload;
    },
    updateRevenueStartYear: (state, action) => {
      state.baselineScenarioData.settings.
      financing.revenues.taxRevenueSettings.start =
        action.payload.value;
      state.baseScenDataToSend.settings.
      financing.revenues.taxRevenueSettings.start =
        action.payload.value;
      state.value.revEndYearArr = startEndYearLimiter(
        state.value.scenRevenueYearArr,
        action.payload.value,
        "start"
      );
    },
    updateRevenueEndYear: (state, action) => {
      state.baselineScenarioData.settings.
      financing.revenues.taxRevenueSettings.end =
        action.payload.value;
      state.baseScenDataToSend.settings.
      financing.revenues.taxRevenueSettings.end =
        action.payload.value;
      state.value.revStartYearArr = startEndYearLimiter(
        state.value.scenRevenueYearArr,
        action.payload.value,
        "end"
      );
    },
    resetScenRevStartYear: (state) => {
      state.value.revStartYearArr = state.value.scenRevenueYearArr;
    },
    resetScenRevEndYear: (state) => {
      state.value.revEndYearArr = state.value.scenRevenueYearArr;
    },
    updateRevenueInprovTax: (state, action) => {
      state.baselineScenarioData.settings.
      financing.revenues.taxRevenueSettings.improvementTaxRevenue =
        action.payload.value;
      state.baseScenDataToSend.settings.
      financing.revenues.taxRevenueSettings.improvementTaxRevenue =
        action.payload.value;
    },
    updateLastUrl: (state, action) => {
      state.value.lastUrl = action.payload;
    },
    updateStaticPage: (state, action) => {
      state.value.staticPage = action.payload;
    },
    stateSwapByTab: () => {
      updateScenarioEnabled("scenario");
    },
    updateFlagWarningMessage: (state, action) => {
      state.value.flagWarningMessage = action.payload;
    },
    updateSavedFromNavBtn: (state, action) => {
      state.value.savedFromNavigationBtn = action.payload;
    },
    updateSaveResetItemNamePath: (state, action) => {
      state.value.saveResetItemNamePath = action.payload;
    },
    updateNanWarningMsg: (state, action) => {
      state.value.nanWarningMsg = action.payload;
    },
    updateAllowUserToNavigate: (state, action) => {
      state.value.allowUserToNavitgate = action.payload;
    },
    updateNextPageToNavigate: (state, action) => {
      state.value.nextPageToNavigate = action.payload;
    },
    updateNextSubPageToNav: (state, action) => {
      state.value.nextSubPageToNav = action.payload;
    },
    updateMainTabsWarningMsg: (state, action) => {
      state.value.mainTabsWarningMsg = action.payload;
    },
  },
});

const sharedSliderInfoFormatter = (
  state,
  itemName,
  sliderValue,
  fromUpdate
) => {
  const isDecimal = !Number.isInteger(sliderValue);

  switch (itemName) {
    case "shareSdgSpendingForInfrastructure":
      state.baselineScenarioData.settings.
      financing.publicExpenditure.shareSdgSpendingForInfrastructure =
        fromUpdate ? sliderValue : sliderValue / 100;
      state.baseScenDataToSend.settings.
      financing.publicExpenditure.shareSdgSpendingForInfrastructure =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "alphaPrivateCapitalOutputElasticity":
      state.baselineScenarioData.settings.
      advanced.productionFunction.alphaPrivateCapitalOutputElasticity =
        sliderValue;
      state.baseScenDataToSend.settings.
      advanced.productionFunction.alphaPrivateCapitalOutputElasticity =
        sliderValue;
      break;
    case "betaPublicCapitalExponent":
      state.baselineScenarioData.settings.
      advanced.productionFunction.betaPublicCapitalExponent =
        sliderValue;
      state.baseScenDataToSend.settings.
      advanced.productionFunction.betaPublicCapitalExponent =
        sliderValue;
      break;
    case "thetaPrivatelyManagedInfrastructureEfficiencyGain":
      state.baselineScenarioData.settings.
      advanced.productionFunction.
      thetaPrivatelyManagedInfrastructureEfficiencyGain =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.productionFunction.
      thetaPrivatelyManagedInfrastructureEfficiencyGain =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "sigmaEducationRelatedLaborProductivityElasticity":
      state.baselineScenarioData.settings.
      advanced.productionFunction.
      sigmaEducationRelatedLaborProductivityElasticity =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.productionFunction.
      sigmaEducationRelatedLaborProductivityElasticity =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "publicCapitalDepreciation":
      state.baselineScenarioData.settings.
      advanced.physicalCapitalStocks.publicCapitalDepreciation =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.physicalCapitalStocks.publicCapitalDepreciation =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "privateCapitalDepreciation":
      state.baselineScenarioData.settings.
      advanced.physicalCapitalStocks.privateCapitalDepreciation =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.physicalCapitalStocks.privateCapitalDepreciation =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "humanCapitalDepreciation":
      state.baselineScenarioData.settings.
      advanced.humanCapital.humanCapitalDepreciation =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.humanCapital.humanCapitalDepreciation =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "humanCapitalDiffusionParameter":
      state.baselineScenarioData.settings.
      advanced.humanCapital.humanCapitalDiffusionParameter =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.humanCapital.humanCapitalDiffusionParameter =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "educationSpendingRelatedNewHumanCapitalElasticity":
      state.baselineScenarioData.settings.
      advanced.humanCapital.educationSpendingRelatedNewHumanCapitalElasticity =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.humanCapital.educationSpendingRelatedNewHumanCapitalElasticity =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    case "studentsRelatedNewHumanCapitalElasticity":
      state.baselineScenarioData.settings.
      advanced.humanCapital.studentsRelatedNewHumanCapitalElasticity =
        fromUpdate ? sliderValue * 100 : sliderValue;
      state.baseScenDataToSend.settings.
      advanced.humanCapital.studentsRelatedNewHumanCapitalElasticity =
        isDecimal ? sliderValue : sliderValue / 100;
      break;
    default:
      break;
  }
};

const limitDecimalsObj = (objToVerify, justObj = false) => {
  for (const property in objToVerify) {
    if (justObj) {
      objToVerify[property] = Number(
        parseFloat(objToVerify[property]).toFixed(2)
      );
    } else if (Array.isArray(objToVerify[property])) {
      objToVerify[property] = objToVerify[property].map((item) =>
        Number(parseFloat(item).toFixed(2))
      );
    } else {
      objToVerify[property];
    }
  }
  return objToVerify;
};

const startEndYearLimiter = (arrToSlice, currentValue, limitIndicator) => {
  let slicedArr = [];

  if (arrToSlice.length === 1) {
    return slicedArr;
  } else if (limitIndicator === "start") {
    slicedArr = arrToSlice.slice(arrToSlice.indexOf(currentValue));
  } else {
    slicedArr = arrToSlice.slice(
      0,
      arrToSlice.indexOf(currentValue) === 0
        ? 1
        : arrToSlice.indexOf(currentValue)
    );
  }
  return slicedArr;
};

// Action creators are generated for each case reducer function
export const {
  getResponseStatus,
  selectCountry,
  updateSDGKey,
  selectYear,
  setUniqueKey,
  addCountryList,
  addYearList,
  updateMacroGDP,
  updateMacroExternal,
  updateMacroInflaton,
  updateExchangeRateRadio,
  updateAdvanceProdFunc,
  changeCountry,
  addAdvPhyCap,
  addAdvHumCap,
  addInitLevels,
  updateSDGCost,
  updateGDPCalibrate,
  updateSliderInfo,
  updateSDGInitialLevels,
  updateDebtFinancing,
  addRevenue,
  updateSpending,
  addPrivateFinanceData,
  toggleShowUniqueKey,
  addChartData,
  selectedCountry,
  getSDGBaselineScenarioData,
  getScenarioDashboardData,
  swapTempState,
  getSavedData,
  updateRevenues,
  selectedPlanningYear,
  updatePrivateFinancingData,
  updateScenarioEnabled,
  updateDashboardEnabled,
  updateRevenueTaxReform,
  updateRevenueStartYear,
  updateRevenueEndYear,
  resetScenRevStartYear,
  resetScenRevEndYear,
  updateRevenueInprovTax,
  updateLastUrl,
  updateProgressBar,
  updateStaticPage,
  updateStateNotSaved,
  updateOpenWarningMessage,
  updateHandleWarningSave,
  updateSaveTabSelection,
  updateSubTabSelection,
  updateFlagWarningMessage,
  stateSwapByTab,
  updateSavedFromNavBtn,
  updateSaveResetItemNamePath,
  resetSingleItemFromState,
  updateLandingProgressBar,
  updateRemoveBackgroundImage,
  updateNanWarningMsg,
  updateAllowUserToNavigate,
  updateNextPageToNavigate,
  updateMainTabsWarningMsg,
  updateNextSubPageToNav,
  updateResetWarningMessage,
} = ReducerSlice.actions;
export const getStore = ({ country }) => country;

export default ReducerSlice.reducer;
