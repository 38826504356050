import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Grid } from "@material-ui/core";
import { Slider } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import { achieveSector } from "../css/custom";
import { useTranslation } from "react-i18next";
import { getStore } from "../store/reducerSlice";
const CustomSlider = withStyles({
  root: {
    color: "#234E90",
    height: 12,
    padding: "13px 0",
    borderRadius: 0,
    paddingTop: 10,
  },
  track: {
    color: "#009FE4",
    height: 12,
    borderRadius: "20px 0px 0px 20px",
  },
  rail: {
    height: 12,
    borderRadius: 5,
  },
  thumb: {
    "&.Mui-disabled": {
      height: 15,
      width: 2,
      margin: 0,
      fontSize: 12,
      textAlign: "center",
      color: "#234E90",
      zIndex: 0,
    },
    height: 15,
    width: 2,
    bottom: 13,
    margin: 0,
    borderRadius: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "none", //#ccc 0 2px 3px 1px",
    },
  },
  valueLabel: {
    top: "-22px",
    fontSize: 30,
    textAlign: "center",
    fontFamily: "'MuseoSans 500'",
    letterSpacing: "-0.0545455px",
    color: "#333333",
    "& *": {
      background: "transparent",
      color: "#009CDE",
      fontFamily: "'MuseoSans 500'",
    },
  },
  mark: {
    display: "none",
  },
  markLabel: {
    "font-family": "'MuseoSans 500'",
    color: "#000000",
    "&[data-index='0']": {
      left: ["5px", "!important"],
    },
    "&[data-index='1']": {
      left: ["98%", "!important"],
    },
  },
})(Slider);

const DashBaseSlider = withStyles({
  root: {
    color: "#234E90",
    height: 12,
    padding: "13px 0",
    borderRadius: 0,
    paddingTop: 10,
  },
  track: {
    color: "#78BE20",
    height: 12,
    borderRadius: "20px 0px 0px 20px",
  },
  rail: {
    height: 12,
    borderRadius: 5,
  },
  thumb: {
    "&.Mui-disabled": {
      height: 15,
      width: 2,
      margin: 0,
      fontSize: 12,
      textAlign: "center",
      color: "#234E90",
      zIndex: 0,
    },
    height: 15,
    width: 2,
    bottom: 13,
    margin: 0,
    borderRadius: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "none", //#ccc 0 2px 3px 1px",
    },
  },
  valueLabel: {
    top: "-22px",
    fontSize: 30,
    textAlign: "center",
    fontFamily: "'MuseoSans 500'",
    letterSpacing: "-0.0545455px",
    color: "#333333",
    "& *": {
      background: "transparent",
      color: "#78BE20",
      fontFamily: "'MuseoSans 500'",
    },
  },
  mark: {
    display: "none",
  },
  markLabel: {
    "font-family": "'MuseoSans 500'",
    color: "#000000",
    "&[data-index='0']": {
      left: ["5px", "!important"],
    },
    "&[data-index='1']": {
      left: ["98%", "!important"],
    },
  },
})(Slider);

export default function AchieveGoal({
  dashProjection = [],
  dashYearToAchieve = "",
  dashLabel = "",
}) {
  const store = useSelector(getStore);
  const projectionPeriods = store.baselineScenarioData.projectionPeriods || [];
  const yearToAchieve = store.baselineScenarioData.yearToAchieve;
  const dashBoardEnabled = store.value.isDashboardEnabled;
  const classes = achieveSector();
  const { t } = useTranslation();

  if (dashBoardEnabled) {
    return (
      <Box
        borderRadius={4}
        p={3}
        component="div"
        className={`${classes.boxMargin}`}
      >
        <Box
          textAlign="center"
          fontSize={18}
          className={classes.headerFont}
        >
          {dashLabel}
        </Box>
        <Box pt={4}>
          {dashYearToAchieve === -1 ? (
            <Box textAlign="center" className={
              dashLabel === "Baseline" ?
              classes.dashBaseBeyondAchieve :
              classes.dashScenBeyondAchieve
              }>
              {t("BeyondAchieve") +
                " " +
                dashProjection[dashProjection.length - 1]}
            </Box>
          ) : (
            dashLabel === "Baseline" ? 
            <DashBaseSlider
            defaultValue={dashYearToAchieve ? dashYearToAchieve : 0}
            value={dashYearToAchieve ? dashYearToAchieve : 0}
            min={dashProjection[0]}
            max={dashProjection[dashProjection.length - 1]}
            disabled={true}
            valueLabelDisplay="on"
            aria-labelledby="Goal"
            marks={[
              {
                value: dashProjection[0],
                label: dashProjection[0],
              },
              {
                value: dashProjection[dashProjection.length - 1],
                label: dashProjection[dashProjection.length - 1],
              },
            ]}
          />
            :
            <CustomSlider
              defaultValue={dashYearToAchieve ? dashYearToAchieve : 0}
              value={dashYearToAchieve ? dashYearToAchieve : 0}
              min={dashProjection[0]}
              max={dashProjection[dashProjection.length - 1]}
              disabled={true}
              valueLabelDisplay="on"
              aria-labelledby="Goal"
              marks={[
                {
                  value: dashProjection[0],
                  label: dashProjection[0],
                },
                {
                  value: dashProjection[dashProjection.length - 1],
                  label: dashProjection[dashProjection.length - 1],
                },
              ]}
            />
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Grid container className={classes.achieveGoals} >
        <Grid xs={9} className={classes.achieveFont}>
          {t("AchieveGoal")}
        </Grid>
        { yearToAchieve === -1 ? (
          <Grid container justifyContent="center">
            <Typography className={classes.beyondAchieve}>
              {t("BeyondAchieve") +
                " " +
                projectionPeriods[projectionPeriods.length - 1]}
            </Typography>
          </Grid>
        ) : (
          <Grid container justifyContent="center">
            <Grid item xs={9} className={classes.sliderContainer}>
              <CustomSlider
                defaultValue={yearToAchieve ? yearToAchieve : 0}
                value={yearToAchieve ? yearToAchieve : 0}
                min={projectionPeriods[0]}
                max={projectionPeriods[projectionPeriods.length - 1]}
                disabled={true}
                valueLabelDisplay="on"
                aria-labelledby="Goal"
                marks={[
                  {
                    value: projectionPeriods[0],
                    label: projectionPeriods[0],
                  },
                  {
                    value: projectionPeriods[projectionPeriods.length - 1],
                    label: projectionPeriods[projectionPeriods.length - 1],
                  },
                ]}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
