import { React, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Link,
} from "@material-ui/core";
import {
  getStore,
} from "../store/reducerSlice";
import { baselineStyles } from "../css/custom";
import ForwardArrowIcon from "../assets/forwardarrow.svg";
import UniqueKeySaveIcon from "../assets/uniqueKeySave.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomNextButton from "../customComponent/customNextButton";

export default function UniqueKeyPopup(props) {
  const classes = baselineStyles();
  const store = useSelector(getStore);
  const mainTabsWarningMsg = store.value.mainTabsWarningMsg;
  const [copyToClipboard, setCopyToClipboard] = useState(false);

  const handleTooltipClose = () => {
    setCopyToClipboard(false);
  };
  const handleTooltipOpen = () => {
    setCopyToClipboard(true);
  };

  
  if (props.saveWarningMsg) {
    return (
      <Box item className={classes.modal}>
        <Box
          alignContent="center"
          alignItems="center"
          className={classes.modalContent}
        >
          {mainTabsWarningMsg ? (
            <Grid>
              <Typography className={classes.contentHeader}>
                Your updates have not been saved and will be lost if you
                navigate away.
              </Typography>
            </Grid>
          ) : (
            <Grid>
              <Grid>
                <Typography className={classes.contentHeader}>
                  Your updates have not been saved.
                </Typography>
              </Grid>
              <Grid>
                <Typography className={classes.noteAlert}>
                  Savings stores and recalculates the data.
                </Typography>
              </Grid>
            </Grid>
          )}

          <CustomNextButton
            variant="contained"
            onClick={props.saveAndContinue}
            style={{ marginTop:  mainTabsWarningMsg ? "" : "20px" }}
          >
            {"Save and Continue"}
          </CustomNextButton>
          <Box className={classes.noteAlert}>
            <Link href="#" underline="hover" onClick={props.cancelWarningMsg}>
              {"CANCEL"}
            </Link>
          </Box>
        </Box>
      </Box>
    );
  } else if (props.nanWarningMsg) {
    return (
      <Box item className={classes.modal}>
        <Box
          alignContent="center"
          alignItems="center"
          className={classes.modalContent}
        >
          <Grid>
            <Typography className={classes.contentHeader}>
              At least one of your inputs has caused and error in the
              calculations.
            </Typography>
          </Grid>
          <Grid>
            <Grid item lg={4} md={5} sm={12} xs={12}></Grid>
            <Typography className={classes.noteAlert}>
              Please correct the input and save your update.
            </Typography>
            <Grid item lg={4} md={5} sm={12} xs={12}></Grid>
          </Grid>

          <CustomNextButton
            endIcon={""}
            startIcon={""}
            variant="contained"
            onClick={props.cancelWarningMsg}
            style={{ marginTop: "20px" }}
          >
            {"Try Again"}
          </CustomNextButton>

          <Grid>
            <Grid item lg={4} md={5} sm={12} xs={12}></Grid>
            <Typography className={classes.noteAlert}>
            Unable to find the error? 
            </Typography>
            <Typography className={classes.resetValuesAlert}>
            Reset your data to the default values.  
            </Typography>
            <Grid item lg={4} md={5} sm={12} xs={12}></Grid>
          </Grid>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box className={classes.modal}>
        <Box
          alignContent="center"
          alignItems="center"
          className={classes.modalContent}
        >
          <Typography className={classes.contentHeader}>
            Your SDG Unique Key for this task:
          </Typography>
          <Typography className={classes.contentKey}>
            {props.uniqueKey}
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                open={copyToClipboard}
                placement={
                  window && window.matchMedia("(max-width: 420px)").matches
                    ? "top"
                    : "right-start"
                }
                title="copied to clipboard"
              >
                <img
                  className={classes.alignIconWithText}
                  src={UniqueKeySaveIcon}
                  onClick={() => {
                    navigator.clipboard.writeText(props.uniqueKey);
                    handleTooltipOpen();
                  }}
                />
              </Tooltip>
            </ClickAwayListener>
          </Typography>
          <Typography className={classes.contentNote}>
            It is suggested to keep this noted and use this to retreive your
            saved data for 7 days.
          </Typography>
          <CustomNextButton
            endIcon={
              <img className={classes.buttonIcon} src={ForwardArrowIcon} />
            }
            variant="contained"
            onClick={props.onCloseModal}
          >
            {" "}
            {"take me to app"}
          </CustomNextButton>
        </Box>
      </Box>
    );
  }


}
