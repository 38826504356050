import { makeStyles } from "@material-ui/core/styles";
export const countryStyles = makeStyles((theme) => ({

  mainSelectCountry:{
    backgroundRepeat: "no-repeat",
    resize: "both",
    backgroundSize: "cover",
    minHeight: "100vh"
  },
  mainHeader: {
    background: "rgb(255, 255, 255)",
    boxShadow: "0px 0px 0px 0px",
    borderRight: "1px",
  },
  mdtitle: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  smtitle: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  content: {
    paddingTop: theme.spacing(8),
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    minHeight:"100vh"
  },
  countryAndKey: {
    spacing: 0, 
    justifyContent: "center",
    direction:"column",
    borderRadius: "8px",
    marginBottom: "25px",
    background: "#234E90",
    opacity: "0.9",
  },
  landingCards: {
    borderRadius: "8px",
    marginTop:"10px",
    marginBottom: "10px",
    background: `${"linear-gradient(180deg, rgba(35,78,144,1) 46%, rgba(255,255,255,0) 100%)"}`
  },
  cardsHeader:{
    marginTop: "15px",
    paddingTop: "10px",
    margin: "5px",
    textAlign: "center",
    color: "#ffffff",
    "font-family": "'MuseoSans 500'",
  },
  inputplaceholder: {
    borderRadius: "5px", 
    "& input::placeholder": {
      fontSize: 14,
      color: "#333333",
    },
    backgroundColor: "white",
    "font-family": "'MuseoSans'",
  },
  innerBoxStyle: {
    "border-radius": 5,
    width: "109px",
  },
  right: {
    display: "flex",
    direction: "column",
  },

  leftcontent: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "20px"
  },
  middlecontent: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px"
  },
  rightcontent: {
    display: "flex",
  },
  body: {
    fontSize: [14, "!important"],
    color: ["#F2A900", "!important"],
    "font-family": "'MuseoSans 700'",
    textAlign: "justify",
    padding: "0 25px 0 0",
  },
  underBody: {
    fontSize: [14, "!important"],
    color: ["#ffffff", "!important"],
    "font-family": "'MuseoSans'",
    textAlign: "justify",
    padding: "0 25px 0 0",
  },
  sideheading: {
    "font-family": "'MuseoSans 700'",
    fontSize: [16, "!important"],
    color: ["#ffffff", "!important"],
  },
  maintitle: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#090909", "!important"],
    paddingLeft: "20px",
  },
  cardtext: {
    "font-family": "'MuseoSans 500'",
    fontSize: [12, "!important"],
    color: ["#333333", "!important"],
    textAlign: "center",
  },
  uniquekeytext: {
    "font-family": "'MuseoSans'",
    fontSize: [11, "!important"],
    color: ["#ffffff", "!important"],
    textAlign: "justify",
    padding: "0 25px 30px 0",
  },
  wrapper: {
    width: "200px",
    height: "200px",
    display: "flex",
  },

  separator: {
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
    color: "black",
  },

  vertical: {
    "border-left": "1px solid #d3d7da",
    "flex-grow": 1,
    width: "1px",
  },
  getstart: {
    "border-bottom": "1px solid #d3d7da",
    "box-sizing": "border-box",
    height: "68px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  icon: {
    width: "80px",
    height: "80px",
    backgroundColor: "none",
  },
  boxStyle: {
    width: "200px",
    height: "200px",
    backgroundColor: "rgb(255 255 255 / 80%)",
    border: "1px solid #E5E5E5 ",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

export const commonStyles = {
  maxWidth: {
    width: "4em",
    height: "2em",
    padding: "5px",
  },
  alignCenter: {
    "text-align": "center",
  },
  alignSelf: {
    "align-self": "center",
  },
  dataRow: {
    "border-bottom": "1px solid #ebebeb",
    "border-left": "5px solid #ffffff",
    "padding-left": 10,
    height: 50,
    marginBottom: 5,
    "&:last-child": {
      marginBottom: 15,
    },
  },
  dataHeader: {
    padding: 10,
    "border-bottom": "1px solid #dddddd",
    marginBottom: 5,
  },
  marginHeight: {
    marginTop: 20,
  },
};

export const baselineStyles = makeStyles((Theme) => ({
  paper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
  tooltip: {
    display: "flex",
    alignItems: "center",
  },
  alertText: {
    "font-family": "'MuseoSans'",
    color: "#000000",
    fontSize: 14,
  },
  icon: {
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  spacing: {
    [Theme.breakpoints.down(`${"md"}`)]: {
      width: "100%",
    },
  },
  saveIcon: {
    paddingLeft: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 5,
    },
    marginTop: 10,
  },
  InfoSpecs: {
    display: "inline",
    margin: "auto",
    marginLeft: 5,
  },
  revenueIconBox: {
    whiteSpace: "nowrap",
    [Theme.breakpoints.up(`${"sm"}`)]: {
      paddingLeft: 20,
    },
  },
  button: {
    display: "flex",
    alignItems: "center",
  },
  buttonIcon: {
    margin: 2,
  },
  sdgtitle: {
    fontSize: "17px",
    color: "#000000",
    marginBottom: "20px",
  },
  headrow: {
    marginBottom: "3%",
  },
  borderstyle: {
    border: "2px solid #4999d0",
    width: "56px",
    height: "56px",
    "border-radius": "50px",
    align: "center",
    padding: "15px",
    "box-sizing": "border-box",
  },
  rowelement: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  textWrap: {
    color: ["#121212", "!important"],
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2 /* number of lines to show */,
    "-webkit-box-orient": "vertical",
  },
  costingTableHeaderText: {
    textAlign: "right",
    paddingRight: 5,
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 14,
    },
  },
  tableHeaderText: {
    textAlign: "right",
    paddingRight: 5,
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 14,
    },
  },
  buttonContainer: {
    marginTop: "20px",
    width: "inherit",
    marginLeft: "-20px",
    justifyContent: "center",
  },
  buttonAlignRight: {
    textAlign: "-webkit-center",
    maxWidth: "none",
    [Theme.breakpoints.up(`${"md"}`)]: {
      textAlign: "-webkit-right",
      maxWidth: "min-content",
    },
  },
  hideItem: {
    display: "none",
  },
  mainButton: {
    width: "100%",
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
  },
  paddingButton: {
    padding: 9,
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
    color: "red",
    width: "100%",
    [Theme.breakpoints.up(`${"md"}`)]: {
      width: "fit-content",
    },
  },

  //for dbet
  alignCenter: {
    "text-align": "center",
  },
  alignSelf: {
    "align-self": "center",
  },
  dataRow: {
    alignItems: "center",
    padding: "10px 5px",
    minHeight: 60,
    marginBottom: 5,
    "border-bottom": "1px solid #ebebeb",
    "&:last-child": {
      marginBottom: 15,
    },
  },
  dataRow1: {
    "border-left": "5px solid #ffffff",
    "padding-left": 10,
    height: 50,
    marginBottom: 5,
    "&:last-child": {
      marginBottom: 15,
    },
  },
  dataHeader: {
    height: 50,
    "border-bottom": "1px solid #dddddd",
    marginBottom: 5,
  },
  marginHeight: {
    marginTop: 20,
  },
  content: {
    backgroundColor: "#F8FAFC",
  },
  containerRightShadow: {
    "box-shadow": "30px 2px 15px -21px rgba(0,0,0,0.07)",
    width: "100%",
    height: "100%",
  },

  //for fonts of baselineoverview
  bstitle: {
    "font-family": "'MuseoSans'",
    fontSize: 22,
    color: "#000000",
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 26,
    },
  },
  bscontent: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#E35205", "!important"],
    marginTop: "32px",
  },
  bslableBold: {
    fontSize: 14,
    "font-family": "'MuseoSans 500'",
    color: "#000000",
  },
  tableDataPaddingRight: {
    paddingRight: 7,
  },
  bslable: {
    "font-family": "'MuseoSans'",
    color: "#121212",
    fontSize: 14,
  },
  subBslable: {
    "font-family": "'MuseoSans'",
    fontSize: 14,
    color: "#000000",
    paddingLeft: 20,
  },
  tableDataText: {
    "font-family": "'MuseoSans'",
    fontSize: 14,
    color: "#121212",
    paddingRight: 6,
  },
  sectiontitle: {
    fontSize: [18, "!important"],
    color: ["#000000", "!important"],
  },
  title: {
    fontSize: [16, "!important"],
    color: ["#000000", "!important"],
  },
  costingtitle: {
    fontSize: [17, "!important"],
    color: ["#000000", "!important"],

    marginBottom: "20px",
  },
  firstSubHeadingMargin: {
    marginTop: 42,
    [Theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  costinglable: {
    fontSize: [14, "!important"],
    color: ["#231F20", "!important"],
    fontWeight: "300",
  },
  infoIcon: {
    paddingLeft: 5,
    position: "relative",
    top: 5,
  },
  warningBox: {
    width: "fit-content",
    padding: 8,
    backgroundColor: "white",
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px,
         rgb(0 0 0 / 14%) 0px 2px 2px 0px,
          rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    marginBottom: "42px",
  },
  btnlable: {
    fontSize: [12, "!important"],
  },
  costLink: {
    fontWeight: 500,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#234E90",
    textDecoration: "none",
    cursor: "pointer",
    "& a": {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        textDecoration: "none",
      },
      "& span": {
        padding: "0 5px",
      },
    },
  },
  modal: {
    position: "fixed",
    "z-index": 1,
    paddingTop: 150,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  },
  modalContent: {
    backgroundColor: "#ffffff",
    "border-radius": 4,
    margin: "auto",
    padding: 24,
    border: "1px solid #888",
    width: "min-content",
    textAlign: "center",
    boxShadow: `0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12),
    0px 8px 10px rgba(0, 0, 0, 0.2)`,
    [Theme.breakpoints.up("md")]: {
      width: "31.3%",
    },
  },
  contentHeader: {
    "font-family": "'MuseoSans 500'",
    fontSize: 18,
    marginBottom: 20,
    letterSpacing: "0.15",
  },
  contentKey: {
    "font-family": "'MuseoSans 500'",
    fontSize: 20,
    marginBottom: 20,
    letterSpacing: "0.25",
    "& .MuiTooltip-tooltip":{
      fontSize: "15px",
    }
  },
  contentNote: {
    "font-family": "'MuseoSans'",
    fontSize: 14,
    marginBottom: 20,
    letterSpacing: "0.25",
  },
  alignIconWithText: {
    verticalAlign: "text-bottom",
    paddingLeft: 3,
    cursor: "pointer",
  },
  continueWithout: {
    "font-family": "'MuseoSans'",
    fontSize: 14,
    letterSpacing: "0.25",
    marginTop: 15,
  },
  contentHeaderAlert: {
    "font-family": "'MuseoSans'",
    fontSize: 15,
    letterSpacing: "0.25",
    marginTop: 15,
    marginBottom: 16,
  },
  noteAlert: {
    "font-family": "'MuseoSans'",
    fontSize: 15,
    letterSpacing: "0.25",
    marginTop: 15,
  },
  resetValuesAlert: {
    "font-family": "'MuseoSans'",
    fontSize: 15,
    letterSpacing: "0.25",
  },
  secondNoteAlert: {
    "font-family": "'MuseoSans'",
    fontSize: 15,
    letterSpacing: "0.25",
    marginBottom: 16,
  },
}));

export const appStyles = makeStyles(() => ({
  appPage: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    placeContent: "center"
  },
}));

export const footerStyles = makeStyles(() => ({
  footerStyle: {
    justifyContent: "center",
    display: "flex",
    borderTop: "1px solid #cccccc",
    backgroundColor: "#F8FAFC",
  },
  textPadding: {
    margin: "20px",
  },
}));

//macro
export const commonPageStyles = (Theme) => ({
  navigationBox: {
    marginTop: "32px",
    marginBottom: "58px",
    marginLeft: "50px",
    marginRight: "50px",
  },
  dropdownYear: {
    "font-family": "'MuseoSans'",
    color: "red",
    fontSize: ["22", "!important"],
  },
  paper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
  inflationIcon: {
    paddingRight: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 10,
    },
  },
  inflationDisabled: {
    opacity: 0.5,
    display: "none",
  },
  subHeading: {
    color: "#121212",
    paddingLeft: 10,
  },
  macroPadding: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  scenarioOverviewPadding:{
    paddingTop: "10px", 
    paddingBottom: "10px"
  },
  tooltip: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    display: "flex",

    alignItems: "center",
  },
  sdgtitle: {
    fontSize: "17px",
    color: "#000000",
    marginBottom: "20px",
  },
  headrow: {
    marginBottom: "3%",
  },
  borderstyle: {
    border: "2px solid #4999d0",
    width: "56px",
    height: "56px",
    "border-radius": "50px",
    align: "center",
    padding: "15px",
    "box-sizing": "border-box",
  },
  textWrap: {
    color: "#121212",
  },
  rowelement: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  //for dbet
  alignCenter: {
    "text-align": "center",
  },
  tableHeaderText: {
    textAlign: "right",
    paddingRight: 5,
    "font-family": "'MuseoSans 500'",
    color: "#121212",
    fontSize: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      fontSize: 14,
    },
  },
  alignSelf: {
    "align-self": "center",
  },
  iconBox: {
    whiteSpace: "nowrap",
    textAlign: "center",
    "& #save": {
      display: "inline",
      paddingTop: "5px",
      paddingLeft: "15px",
    },
    "& #refrsh": {
      display: "inline",
      paddingBottom: "2px",
      paddingLeft: "10px",
    },
  },
  InfoSpecs: {
    display: "inline",
    margin: "auto",
  },
  iconSpecs: {
    //display: "none",
    paddingRight: 5,
    cursor: "pointer",
    "&:last-child": {
      paddingRight: 10,
    },
  },
  dataRow: {
    padding: "10px 5px",
    "border-bottom": "1px solid #ebebeb",
    "border-left": "5px solid #F8FAFC",
    alignItems: "center",
    minHeight: 60,
  },
  dataRow1: {
    "padding-left": 10,
    height: 50,
    marginBottom: 5,
    "&:last-child": {
      marginBottom: 15,
    },
  },
  dataHeader: {
    height: 50,
    "border-bottom": "1px solid #dddddd",
    marginBottom: 5,
  },
  dataHeader1: {
    height: 50,
    width: "100%",
    marginBottom: 5,
  },
  border: {
    marginLeft: 2,
    marginRight: 1,
    "border-bottom": "1px solid #cccccc",
    "box-sizing": "border-box",
  },
  marginHeight: {
    marginTop: 20,
  },
  content: {
    backgroundColor: "#F8FAFC",
    minHeight: "100vh",
  },
  containerRightShadow: {
    "box-shadow": "30px 2px 15px -21px rgba(0,0,0,0.07)",
    width: "100%",
    height: "100%",
  },
  Box: {
    alignItems: "center",
  },
  AlertBox: {
    border: "none",
    width: "100%",
    padding: 8,
    marginTop: 20,
    paddingRight: 15,
    backgroundColor: "#FFFFFF",
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px,
             rgb(0 0 0 / 14%) 0px 2px 2px 0px,
              rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 25,
      width: "fit-content",
    },
    "& .MuiIconButton-label": {
      paddingRight: 18,
      paddingLeft: 10,
    },
  },
  onYearChange: {
    "& #info": {
      display: "none",
    },
    "& #save": {
      display: "inline",
    },
    "& #refrsh": {
      display: "inline",
    },
  },
  changeYear: {
    marginBottom: 30,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginBottom: 48,
    },
    backgroundColor: "#ffffff",
    "&:hover": {
      "box-shadow": `rgb(0 0 0 / 20%) 0px 2px 1px -1px, 
      rgb(0 0 0 / 14%) 0px 1px 1px 0px, 
      rgb(0 0 0 / 12%) 0px 1px 3px 0px`,
    },
  },
  abovecontentMargin: {
    marginBottom: 30,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginBottom: 48,
    },
  },
  boxMargin: {
    marginBottom: 22
  },
  requiredEditstMargin: {
    marginTop: 20,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 10,
    },
  },
  belowcontentMargin: {
    marginTop: 42,
    marginBottom: 12,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 20,
    },
  },
  buttonMargin: {
    margin: "34px 0px",
    [Theme.breakpoints.up(`${"md"}`)]: {
      margin: "61px 0px",
    },
  },
  leftMargin: {
    marginLeft: "10px"
  },
  warningOverIcon: {
    marginTop: "10px",
    marginRight: "5px",
  },
  boicon: {
    marginRight: "5px",
  },
  textfield: {
    fontSize: 14,
    color: "#333333",
  },
  inputplaceholder: {
    fontSize: 14,
    "& input::placeholder": {
      fontSize: 14,
      color: "#333333",
    },
  },
  //for fonts of baselineoverview
  bstitle: {
    "font-family": "'MuseoSans'",
    fontSize: [26, "!important"],
    color: ["#000000", "!important"],
    marginBottom: "25px",
  },
  inflationTitle: {
    "font-family": "'MuseoSans'",
    fontSize: [26, "!important"],
    color: ["#000000", "!important"],
  },
  bscontent: {
    "font-family": "'MuseoSans'",
    fontSize: [16, "!important"],
    color: ["#000000", "!important"],
    textAlign: "justify",
  },
  text: {
    "font-family": "'MuseoSans'",
    fontSize: [14, "!important"],
    color: ["#000000", "!important"],
  },
  bslable: {
    "font-family": "'MuseoSans'",
    fontSize: [14, "!important"],
    color: ["#000000", "!important"],
    textAlign: "justify",
    marginRight: 20,
  },
  bslable1: {
    "font-family": "'MuseoSans'",
    fontSize: [16, "!important"],
    color: ["#000000", "!important"],
  },
  sclink: {
    "font-family": "'MuseoSans'",
    fontSize: [14, "!important"],
    color: ["#009CDE", "!important"],
    textAlign: "justify",
  },
  changeYearSub: {
    "font-family": "'MuseoSans'",
    fontSize: [12, "!important"],
    color: ["#4D4D4D", "!important"],
    paddingRight: "10px"
  },
  sectiontitle: {
    "font-family": "'MuseoSans 500'",
    fontSize: [18, "!important"],
    color: ["#E35205", "!important"],
  },
  title: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#E35205", "!important"],
  },
  titleMarginX: {
    marginTop: 20,
    marginBottom: 5,
  },
  inflationSubTitle: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#E35205", "!important"],
    marginTop: 32,
  },
  costingtitle: {
    fontSize: [17, "!important"],
    color: ["#000000", "!important"],

    marginBottom: "20px",
  },
  costinglable: {
    fontSize: [14, "!important"],
    color: ["#231F20", "!important"],
    fontWeiht: "300",
  },
  infoIcon: {
    paddingLeft: 5,
    position: "relative",
    top: 5,
  },
  warningBox: {
    width: "100%",
    padding: 8,
    backgroundColor: "white",
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px,
         rgb(0 0 0 / 14%) 0px 2px 2px 0px,
          rgb(0 0 0 / 12%) 0px 1px 5px 0px`,
    marginBottom: "42px",
    [Theme.breakpoints.up(`${"md"}`)]: {
      width: "fit-content",
    },
  },
  btnlable: {
    fontSize: [12, "!important"],
  },
  costLink: {
    fontWeight: 500,
    fontSize: 12,
    textTransform: "uppercase",
    color: "#234E90",
    textDecoration: "none",
    cursor: "pointer",
    "& a": {
      display: "flex",
      alignItems: "center",
      "&:hover": {
        textDecoration: "none",
      },
      "& span": {
        padding: "0 5px",
      },
    },
  },
  changeYearSaveSVG:{
    width: "20px",
    height: "20px",
  },
  changeYearBtn: {
    width: "80px",
    height: "35px",
    fontSize: 10,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
  },
  mainButton: {
    width: "100%",
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
  },
  buttonContainer: {
    marginTop: "20px",
    width: "inherit",
    marginLeft: "-20px",
    justifyContent: "center",
  },
  hideItem: {
    display: "none",
  },
  buttonIcon: {
    margin: 2,
  },
  paddingButton: {
    padding: 9,
    fontSize: 12,
    "font-family": "'MuseoSans 500'",
    textTransform: "uppercase",
    color: "#004C97",
    width: "100%",
    margin: "20.8px 20.8px 0 20.8px",
    [Theme.breakpoints.up(`${"md"}`)]: {
      width: "fit-content",
      margin: 0,
    },
  },
  baseText: {
    textAlign: "justify",
    marginLeft: 35,
    marginTop: 20,
    marginRight: 35,
    marginBottom: 30,
    padding: 0,
    "font-family": "'MuseoSans'",
    color: "#E35205",
    fontSize: 13,
    [Theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
});

export const headerTheme = makeStyles({
  appBar: {
    top: 100,
    zIndex: 1,
  },
  appBar2: {
    top: 200,
    zIndex: 0,
  },

  bgcolors: {
    background: `${"linear-gradient(90deg, rgba(255,255,255,1) 61%, rgba(217,215,215,1) 79%, rgba(255,255,255,1) 100%)"}`,
  },
  Tab_color: {
    color: "blue",
    marginLeft: "auto",
  },
  header_text: {
    textAlign: "left",
  },
});

export const finNeedTheme = makeStyles((Theme) => ({
  primaryColor: {
    fontSize: 50,
    textAlign: "center",
    letterSpacing: "-0.0545455px",
    color: "#009FE4",
  },
  secondaryColor: {
    color: "#040404",
    fontSize: 18,
    textAlign: "center",
  },
  boxMargin: {
    marginTop: 8,
    [Theme.breakpoints.up(`${"md"}`)]: {
      marginTop: 0,
      marginLeft: 16,
      marginRight: 16,
    },
    border: "solid",
    borderWidth: "1.5px",
    borderRadius: "15px",
    borderColor: "#009CDE",
    backgroundColor: "#ffffff"
  },
  finBoxShadow: {
    background: "linear-gradient(180deg, #F8FAFC 0%, #FFFFFF 100%)",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
  },
  headerFont: {
    "font-family": "'MuseoSans 500'",
    color: "#E35205",
    textAlign: "left",
    "& .boldYear": {
      "font-family": "'MuseoSans 700'",
      fontSize: 18,
      fontWeight: "bold",
    },
  },
}));

export const achieveSector = makeStyles(() => ({
  root: {
    color: "#6f8eff",
    height: 3,
    padding: "13px 0",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -9,
    marginLeft: -11,
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    color: "#fff",
  },
  achieveGoals: {
    paddingTop: 5,
    border: "solid",
    borderWidth: "1.5px",
    borderRadius: "15px",
    borderColor: "#009CDE",
    backgroundColor: "#ffffff",
    height: "210px",
    paddingRight:10
  },
  finBoxShadow: {
    background: "linear-gradient(180deg, #F8FAFC 0%, #FFFFFF 100%)",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.15)",
  },
  secondaryColor: {
    color: "#040404",
    fontSize: 18,
    textAlign: "center",
  },
  sliderContainer:{
    marginTop: "35px"
  },
  achieveFont: {
    "font-family": "'MuseoSans 500'",
    color: "#E35205",
    fontSize: "20px",
    paddingTop: "10px",
    paddingLeft: "20px",
  },
  dashBaseBeyondAchieve: {
    "font-family": "'MuseoSans'",
    color: "#78BE20",
    fontSize: "40px",
  },
  dashScenBeyondAchieve: {
    "font-family": "'MuseoSans'",
    color: "#009FE4",
    fontSize: "40px",
  },
  beyondAchieve: {
    "font-family": "'MuseoSans'",
    color: "#E35205",
    fontSize: "40px",
  },
  dashScenAchieve: {
    "font-family": "'MuseoSans'",
    color: "#009FE4",
    fontSize: "40px",
  },
  dashBaseAchieve: {
    "font-family": "'MuseoSans'",
    color: "#78BE20",
    fontSize: "40px",
  }
}));
