import request from "../request";

const codeKey = "?code=";

let baseUrl  = process.env.REACT_APP_BASE_URL_development;
let KEY_ENV = {};

if (window.location.host && ( window.location.host.includes("dev") 
    || window.location.host.includes("localhost")
)) {
    baseUrl  = process.env.REACT_APP_BASE_URL_DEV;
} else if (window.location.host && window.location.host.includes("qa")) {
    baseUrl  = process.env.REACT_APP_BASE_URL_QA;
} else if (window.location.host && window.location.host.includes("stg")){
    baseUrl  = process.env.REACT_APP_BASE_URL_STG;
} else {
    baseUrl  = process.env.REACT_APP_BASE_URL_PROD; 
}

export function getData(routeUrl) {
        const fullPath = baseUrl+routeUrl;
    return request.GET({
       endpoint: fullPath,
    });
}

export function getSDGandResetData(routeUrl, data) {
    let fullPath = "";
    if(routeUrl === "api/OnResetSdgFinUserSettings"){
     fullPath = baseUrl+routeUrl;
    } else if (routeUrl === "api/OnFetchSdgFinData"){ 
        fullPath = baseUrl+routeUrl;
    }else if (routeUrl === "api/OnFetchSdgFinScenario"){ 
        fullPath = baseUrl+routeUrl;
    }else if (routeUrl === "api/OnResetSdgFinScenario"){ 
        fullPath = baseUrl+routeUrl;
    }else if (routeUrl === "api/OnResetSdgFinScenarioUserSettings"){ 
        fullPath = baseUrl+routeUrl;
    }else if (routeUrl === "api/OnCompareSdgFinBaseline2Scenario"){ 
        fullPath = baseUrl+routeUrl;
    }else if (routeUrl === "api/OnExportSdgFinComparisonData"){ 
        fullPath = baseUrl+routeUrl;
    }
return request.POST({
   endpoint: fullPath,
   body: data,
});
}

export function saveData(routeUrl, data) {
    const fullPath = baseUrl+routeUrl;
    return request.POST({
       endpoint: fullPath,
       body: data,
    });
}

export function postData(routeUrl, data) {
    const fullPath = baseUrl+routeUrl;
    return request.POST({
       endpoint: fullPath,
       body: data,
    });
}

export function retrieveSession(routeUrl, data) {
    const fullPath = baseUrl+routeUrl;
    return request.POST({
       endpoint: fullPath,
       body: data,
    });
}

export function patchDataReq(routeUrl, data) {
    const fullPath = baseUrl+routeUrl+codeKey+KEY_ENV.keyBaseline;
    return request.PATCH({
       endpoint: fullPath,
       body: data,
    });
}