import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import { NavLink } from "react-router-dom";
import {
  updateProgressBar,
  updateSubTabSelection,
  updateOpenWarningMessage,
  updateNextPageToNavigate,
  updateResetWarningMessage,
} from "../store/reducerSlice";

import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createPDF } from "../dashboard/ComparisonDashboard";

import exportIcon from "../assets/export.svg";
import Reset from "../assets/headerReset.svg";
import bsLnIcon from "../assets/baseline.svg";
import ScIcon from "../assets/scenario.svg";
import DsBIcon from "../assets/dashboard.svg";
import { Redirect } from "react-router";
import { Button } from "@material-ui/core";
import i18next from "i18next";

import ExcelExport from "../Excel/ExcelExport";

const t = i18next.getFixedT(null, null, "CountrySelect");

const useStyle = () => ({
  headerBottom: {
    borderBottom: "1px solid #cccccc",
  },
  headerLinks: {
    height: "100%",
    padding: "9px 0",
  },
  tabRoot: {
    "& .MuiButtonBase-root": {
      backgroundColor: "#ffffff",
      opacity: 1,
      flex: 1,
      borderLeft: "1px solid #ffffff",
      "&:last-child": {
        borderRight: "1px solid #ffffff",
      },
    },
  },
  helperItem: {
    fontSize: 11,
    color: "#707372b3",
    "& .design": {
      backgroundColor: "rgba(112, 115, 114, 0.7)",
      border: "1px solid rgba(112, 115, 114, 0.7)",
      borderRadius: 2,
      margin: 0,
      minWidth: 11,
      width: "100%",
    },
    "& .txt": {
      color: "rgba(112, 115, 114, 0.7)",
      width: "100%",
      flex: 1,
      whiteSpace: "nowrap",
      padding: "0 5px",
      textTransform: "uppercase",
    },
  },
  menuLink: {
    "font-family": "'MuseoSans 500'",
    margin: "0 57px 0 0",
    // width: "50%",
    display: "inline-block",
    textAlign: "center",
    cursor: "pointer",
    position: "relative",
    fontSize: 13,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#004C97",
    // fontWeight: 500,
    textDecoration: "none",
    "&:hover": {
      color: "#E35205",
      "text-decoration": "none",
    },
    "&:last-child": {
      margin: 0,
    },
  },
  menuLinkSelect: {
    color: "#E35205",
    "& .triangle": {
      width: 0,
      height: 0,
      position: "absolute",
      bottom: "-25px",
      left: "50%",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderTop: "10px solid #cccccc",
      transform: "translate(-50%, 0%)",
      "&:after": {
        content: "''",
        top: "-11px",
        left: "-9px",
        width: 0,
        height: 0,
        position: "absolute",
        borderLeft: "9px solid transparent",
        borderRight: "9px solid transparent",
        borderTop: "9px solid #ffffff",
      },
    },
  },
  menuLinkFull: {
    width: "100%",
  },
  menuItems: {
    margin: "0 20px",
    position: "relative",
    fontWeight: 500,
    color: "#004C97",
    textDecoration: "none",
    "&:hover": {
      color: "#E35205",
      "text-decoration": "none",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: "-3px",
      left: 0,
      height: 0,
      width: "0%",
      borderBottom: "3px solid #E35205",
      transition: "width 0.3s ease",
    },
    "&:hover:after": {
      width: "100%",
    },
  },
  selectedItem: {
    color: "#E35205",
    "text-decoration": "none",
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: "-3px",
      width: "100%",
      left: 0,
      height: 0,
      borderBottom: "3px solid #z",
    },
  },
  uniqueKey: {
    fontWeight: 300,
    fontSize: 14,
    "& span": {
      fontWeight: "bold",
    },
  },
  version: {
    fontWeight: "bold",
    fontSize: 12,
    "& span": {
      fontWeight: "bold",
      marginLeft: "5px",
    },
  },
  desktopUniqueKey: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#090909", "!important"],
  },
  numberUniqueKey: {
    "font-family": "'MuseoSans 500'",
    fontSize: [16, "!important"],
    color: ["#090909", "!important"],
  },
  reset: {
    "& button": {
      fontSize: 13,
      textTransform: "uppercase",
      color: "#004C97",
      textDecoration: "none",
      cursor: "pointer",
      "& a": {
        display: "flex",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
        "& span": {
          padding: "0 10px",
          fontWeight: "bold",
          "font-family": "'MuseoSans 500'",
          color: "#004C97",
        },
      },
    },
  },
  save: {
    "& button": {
      fontSize: 13,
      paddingRight: "15px",
      textTransform: "uppercase",
      color: "#004C97",
      textDecoration: "none",
      cursor: "pointer",
      "& a": {
        display: "flex",
        alignItems: "center",
        "&:hover": {
          textDecoration: "none",
        },
        "& span": {
          padding: "0 10px",
          fontWeight: "bold",
          "font-family": "'MuseoSans 500'",
          color: "#004C97",
        },
      },
    },
  },
  hdrCntry: {
    display: "flex",
    flexDirection: "column",
    padding: "1.2em",
    boxSizing: "border-box",
    "& p": {
      fontSize: 16,
      fontWeight: "bold",
      textTransform: "uppercase",
    },
  },
  menudropdown: {
    display: "flex",
    "& .menudropdowns": {
      padding: "10px 10px",
      flex: 1,
    },
  },
  dropdownStyle: {
    width: "100%",
    backgroundColor: "red",
  },

  label: {
    marginLeft: "4px",
  },
  select: {
    background: "white",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "4px",
    paddingLeft: "24px",
    paddingTop: "14px",
    paddingBottom: "15px",
    left: 0,
    "&:focus": {
      borderRadius: "4px",
      background: "white",
    },
  },
  paper: {
    left: "10px !important",
    marginTop: 5,
  },
  paperEnd: {
    marginTop: 5,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    "& li.Mui-selected": {
      fontWeight: 700,
    },
  },
  sectionBtn: {
    borderRadius: 5,
  },
  btnSelect: {
    background: "#ffffff",
  },
  bgcolor: {
    background: "#F8FAFC",
  },
});

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMenu: "Overview",
      endPoint: "",
      pdf: false,
      saveCurrentTab: "",
    };
    this.menuItems = {
      costing: ["Overview", "SDG Cost", "Initial Levels"],
      financing: [
        "Overview",
        "Revenues",
        "Debt Financing",
        "Public Expenditure",
        "Private Financing",
      ],
      macro: [
        "Overview",
        "GDP",
        "External Sector",
        "Inflation, Interest and Exchange Rates",
      ],
      advParameter: [
        "Overview",
        "Production Function",
        "Physical Capital Stocks",
        "Human Capital",
      ],
    };
  }

  componentDidMount() {
    const { selectedMenu } = this.state;
    const { activeTab, dataSection, activeURL } = this.props;
    if (
      selectedMenu !== "" &&
      activeTab !== dataSection &&
      !activeURL.includes(selectedMenu)
    ) {
      let path = activeURL.split("/");
      if (path.length > 3 && this.menuItems[path[2]] !== "") {
        this.setState({ selectedMenu: path[2] });
      } else {
        this.setState({ selectedMenu: "Overview" });
      }
    }
  }

  static getDerivedStateFromProps(props) {
    let url = props.history.location.pathname;
    let urlSection = url.split("/");
    if (url === "/" || urlSection.length === 3) {
      return {
        selectedMenu: "Overview",
        endPoint: "",
      };
    } else if (urlSection && urlSection.length > 3) {
      return {
        selectedMenu: urlSection[2],
        endPoint: urlSection[3],
      };
    }
  }

  handleMenuChange = (newValue) => {
    if (this.props.stateNotSaved) {
      this.props.onUpdateNextPageToNavigate(
        `/${this.props.dataSection}/${newValue}/Overview`
      );
      this.props.onUpdateWarningMessage(true);
    } else {
      if (newValue === "Overview") {
        this.setState({ selectedMenu: newValue, endPoint: "Overview" });
        this.props.urlHandler(`/${this.props.dataSection}/Overview`);
        this.props.history.push(`/${this.props.dataSection}/Overview`);
      } else {
        this.setState({ selectedMenu: newValue, endPoint: "Overview" });
        this.props.urlHandler(
          `/${this.props.dataSection}/${newValue}/Overview`
        );
        this.props.history.push(
          `/${this.props.dataSection}/${newValue}/Overview`
        );
      }
    }
  };

  handleSubMenuChange = (e, item, menu, dataSection, urlHandler) => {
    this.props.onUpdateSubTabSelection(`/${dataSection}/${menu}/${item}`);
    if (this.props.stateNotSaved) {
      e.preventDefault();
      urlHandler(this.props.saveSubTabSelection);
      this.props.onUpdateWarningMessage(true);
    }
  };

  pdfHandler = (e) => {
    e.preventDefault();
    this.setState({ pdf: true });
  };

  drpdwnURLGenerator = (newValue) => {
    const { dataSection, urlHandler, history } = this.props;
    const { selectedMenu } = this.state;
    let url = `/${dataSection}/${selectedMenu}/${newValue}`;

    this.setState({ endPoint: newValue });
    urlHandler(url);
    history.push(url);
  };

  subOptions = (menu) => {
    const { dataSection, classes, urlHandler } = this.props;
    return (
      menu !== "" &&
      menu !== "Overview" &&
      this.menuItems[menu].map((item, i) => {
        return (
          <NavLink
            key={i}
            to={`/${dataSection}/${menu}/${item}`}
            className={classes.menuItems}
            activeClassName={classes.selectedItem}
            onClick={(e) => {
              this.handleSubMenuChange(e, item, menu, dataSection, urlHandler);
            }}
          >
            {item}
          </NavLink>
        );
      })
    );
  };

  subOptionsMobile = (menu) => {
    return (
      menu !== "" &&
      menu !== "Overview" &&
      this.menuItems[menu].map((item, i) => {
        return (
          <MenuItem key={i} value={item}>
            {item}
          </MenuItem>
        );
      })
    );
  };

  menuProps = {
    variant: "menu",
    classes: {
      list: this.props.classes.list,
      paper: this.props.classes.paper,
    },
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: "top", horizontal: "center" },
    getContentAnchorEl: null,
  };

  menuEndProps = {
    variant: "menu",
    classes: {
      list: this.props.classes.list,
      paper: this.props.classes.paperEnd,
    },
    anchorOrigin: { vertical: "bottom", horizontal: "center" },
    transformOrigin: { vertical: "top", horizontal: "center" },
    getContentAnchorEl: null,
  };

  render() {
    const { selectedMenu, endPoint } = this.state;
    const { classes, dataSection, urlHandler, handleSection } = this.props;

    if (this.state.pdf) {
      return <Redirect to="/ExportToPdf" />;
    }
    if (dataSection === "dashboard") {
      return (
        <div>
          <AppBar
            elevation={0}
            position="static"
            color={"transparent"}
            className={classes.headerBottom}
          >
            <Toolbar disableGutters={true}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Hidden only={["xs", "sm"]}>
                  <Box pl={2}>
                    <Typography className={classes.version} id="version">
                      {t("version")}
                      <span>{t("date")}</span>
                    </Typography>
                    <Typography
                      className={classes.desktopUniqueKey}
                      id="UniqueKey"
                    >
                      {"SDG Unique Key:"}
                      <span
                        className={classes.numberUniqueKey}
                        style={{ paddingLeft: "5px" }}
                      >{`${this.props.baselineScenarioData.uniqueKey}`}</span>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box borderLeft={1} borderColor="#cccccc" px={2.5}></Box>
                    <Box
                      borderLeft={1}
                      borderColor="#cccccc"
                      px={2.5}
                      textAlign="center"
                    ></Box>
                    <Box
                      borderLeft={1}
                      borderColor="#cccccc"
                      px={2.5}
                      textAlign="center"
                      // className={classes.headerLinks}
                    ></Box>
                  </Box>
                  <Box
                    pr={2}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className={classes.helperItem}
                  ></Box>
                </Hidden>
                <Box xs={6} className={classes.save}>
                  <Button onClick={this.props.exportDataToPDF}>
                    <img
                      src={exportIcon}
                      style={{
                        width: "25px",
                        paddingRight: "5px",
                        paddingBottom: "8px",
                      }}
                    />
                    <span style={{ paddingLeft: "10px" }}>
                      {"Export to PDF"}
                    </span>
                  </Button>
                  <ExcelExport />
                </Box>
                <Hidden only={["md", "lg", "xl"]}>
                  <Box className={classes.hdrCntry}>
                    <>
                      <span>{this.props.country.value.country}</span>

                      <span>{t("date")}</span>
                    </>
                  </Box>
                  <Box>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="baseline"
                      className={`${classes.sectionBtn} ${
                        dataSection === "baseline" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("baseline");
                      }}
                    >
                      <img src={bsLnIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="scenario"
                      className={`${classes.sectionBtn} ${
                        dataSection === "scenario" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("scenario");
                      }}
                    >
                      <img src={ScIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="dashboard"
                      className={`${classes.sectionBtn} ${
                        dataSection === "dashboard" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("dashboard");
                      }}
                    >
                      <img src={DsBIcon} />
                    </IconButton>
                  </Box>
                </Hidden>
              </Grid>
            </Toolbar>
            <Grid sx={{ width: "100%" }}>
              {this.props.progressBar ? (
                <LinearProgress color="primary" />
              ) : (
                <></>
              )}
            </Grid>
          </AppBar>
          <AppBar
            elevation={0}
            position="static"
            color={"transparent"}
            className={classes.bgcolor}
          ></AppBar>
        </div>
      );
    } else {
      return (
        <div>
          <AppBar
            elevation={0}
            position="static"
            color={"transparent"}
            className={classes.headerBottom}
          >
            <Toolbar disableGutters={true}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Hidden only={["xs", "sm"]}>
                  <Box pl={2}>
                    <Typography className={classes.version} id="version">
                      {t("version")}
                      <span>{t("date")}</span>
                    </Typography>
                    <Typography
                      className={classes.desktopUniqueKey}
                      id="UniqueKey"
                    >
                      {"SDG Unique Key:"}
                      <span
                        className={classes.numberUniqueKey}
                        style={{ paddingLeft: "5px" }}
                      >{`${this.props.baselineScenarioData.uniqueKey}`}</span>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box px={2.5} id="BaselineOverview">
                      <NavLink
                        className={`${classes.menuLink} 
                                            ${classes.menuLinkFull} 
                                            ${
                                              selectedMenu === ""
                                                ? classes.menuLinkSelect
                                                : ""
                                            } 
                                            ${classes.headerLinks}`}
                        to={`/${dataSection}/Overview`}
                        // to={"#"}
                        onClick={() => {
                          this.handleMenuChange("Overview");
                          urlHandler("");
                        }}
                        activeClassName={
                          selectedMenu === "Overview"
                            ? classes.menuLinkSelect
                            : ""
                        }
                      >
                        {"Overview"}
                        <Box className={"triangle"} />
                      </NavLink>
                    </Box>
                    <Box
                      borderLeft={1}
                      borderColor="#cccccc"
                      px={2.5}
                      textAlign="center"
                    >
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.helperItem}
                      >
                        <div className={"design"} />
                        {this.props.isScenarioEnabled ? (
                          <div className={"txt"}>{"Policy Edits"}</div>
                        ) : (
                          <div className={"txt"}>{"Required Edits"}</div>
                        )}
                        <div className={"design"} />
                      </Box>
                      <Link
                        className={`${classes.menuLink} ${
                          selectedMenu === "financing"
                            ? classes.menuLinkSelect
                            : ""
                        }`}
                        onClick={() => this.handleMenuChange("financing")}
                      >
                        {"Financing"}
                        <Box className={"triangle"} />
                      </Link>
                      {!this.props.isScenarioEnabled ? (
                        <Link
                          className={`${classes.menuLink} ${
                            selectedMenu === "costing"
                              ? classes.menuLinkSelect
                              : ""
                          }`}
                          onClick={() => this.handleMenuChange("costing")}
                        >
                          {"Costing"}
                          <Box className={"triangle"} />
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box
                      borderLeft={1}
                      borderColor="#cccccc"
                      px={2.5}
                      textAlign="center"
                    >
                      <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        className={classes.helperItem}
                      >
                        <div className={"design"} />
                        <div className={"txt"}>
                          {"Review / Edit with caution"}
                        </div>
                        <div className={"design"} />
                      </Box>
                      <Link
                        className={`${classes.menuLink} ${
                          selectedMenu === "macro" ? classes.menuLinkSelect : ""
                        }`}
                        onClick={() => this.handleMenuChange("macro")}
                      >
                        {"Macro"}
                        <Box className={"triangle"} />
                      </Link>
                      <Link
                        className={`${classes.menuLink}  ${
                          selectedMenu === "advParameter"
                            ? classes.menuLinkSelect
                            : ""
                        }`}
                        underline="none"
                        onClick={() => this.handleMenuChange("advParameter")}
                      >
                        {"Advanced parameter"}
                        <Box className={"triangle"} />
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    pr={2}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    className={classes.helperItem}
                  >
                    <Box xs={6} className={classes.reset}>
                      <Button
                        onClick={() => {
                          this.props.onResetAllBaselineOrScenario();
                        }}
                      >
                        <img src={Reset} style={{ paddingRight: "5px" }} />
                        <span>{"Reset"}</span>
                      </Button>
                    </Box>
                  </Box>
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <Box className={classes.hdrCntry}>
                    <span>{this.props.country.value.country}</span>
                    <span>{t("date")}</span>
                  </Box>
                  <Box>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="baseline"
                      className={`${classes.sectionBtn} ${
                        dataSection === "baseline" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("baseline");
                      }}
                    >
                      <img src={bsLnIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="scenario"
                      className={`${classes.sectionBtn} ${
                        dataSection === "scenario" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("scenario");
                      }}
                    >
                      <img src={ScIcon} />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label="dashboard"
                      className={`${classes.sectionBtn} ${
                        dataSection === "dashboard" ? classes.btnSelect : ""
                      }`}
                      onClick={() => {
                        handleSection("dashboard");
                      }}
                    >
                      <img src={DsBIcon} />
                    </IconButton>
                  </Box>
                </Hidden>
              </Grid>
            </Toolbar>
            <Grid sx={{ width: "100%" }}>
              {this.props.progressBar ? (
                <LinearProgress color="primary" />
              ) : (
                <></>
              )}
            </Grid>
          </AppBar>
          <AppBar
            elevation={0}
            position="static"
            color={"transparent"}
            className={classes.bgcolor}
          >
            <Toolbar disableGutters={true}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.menudropdown}
              >
                <Hidden only={["xs", "sm"]}>
                  {this.subOptions(selectedMenu)}
                </Hidden>
                <Hidden only={["md", "lg", "xl"]}>
                  <FormControl id="selecteID" className={"menudropdowns"}>
                    <Select
                      disableUnderline
                      value={selectedMenu}
                      onChange={(e) => this.handleMenuChange(e.target.value)}
                      classes={{ root: classes.select }}
                      labelId="inputLabel"
                      id="inputLabeldrpdwn"
                      MenuProps={this.menuProps}
                    >
                      <MenuItem value={"Overview"}>Overview</MenuItem>
                      <MenuItem value={"financing"}>Financing</MenuItem>
                      {!this.props.isScenarioEnabled ? (
                        <MenuItem value={"costing"}>Costing</MenuItem>
                      ) : (
                        ""
                      )}
                      <MenuItem value={"macro"}>Macro</MenuItem>
                      <MenuItem value={"advParameter"}>
                        Advanced Parameter
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl id="selecteMenuID" className={"menudropdowns"}>
                    <Select
                      disableUnderline
                      classes={{ root: classes.select }}
                      labelId="inputMenuLabel"
                      id="inputMenuLabeldrpdwn"
                      MenuProps={this.menuEndProps}
                      value={endPoint}
                      onChange={(e) => this.drpdwnURLGenerator(e.target.value)}
                      inputProps={{
                        readOnly: selectedMenu === "Overview" ? true : false,
                      }}
                    >
                      {this.subOptionsMobile(selectedMenu)}
                    </Select>
                  </FormControl>
                  <Box pr={2} className={classes.reset}>
                    <Link>
                      <img
                        src={Reset}
                        onClick={() => {
                          this.props.onResetAllBaselineOrScenario();
                        }}
                      />
                    </Link>
                  </Box>
                </Hidden>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      );
    }
  }
}

const routerWrapper = withRouter(HeaderMenu);

const mapStateToProps = (state) => ({
  baselineScenarioData: state.country.baselineScenarioData,
  country: state.country,
  uniqueKey: state.country.value.uniqueKey,
  isScenarioEnabled: state.country.value.isScenarioEnabled,
  stateNotSaved: state.country.value.stateNotSaved,
  progressBar: state.country.value.progressBar,
  saveSubTabSelection: state.country.value.saveSubTabSelection,
  nextPageToNavigate: state.country.value.nextPageToNavigate,
  resetWarningMessage: state.country.value.resetWarningMessage,
});

const mapDispatchToProps = (dispatch) => ({
  onResetAllBaselineOrScenario: () => {
    dispatch(updateResetWarningMessage(true));
  },
  onSaveAllBaselineOrScenario: () => {
    dispatch(updateProgressBar(true));
    dispatch({ type: "GET_SAVE_DATA" });
  },
  exportDataToPDF: () => {
    dispatch(updateProgressBar(true));
    createPDF().then(() => {
      dispatch(updateProgressBar(false));
    });
  },
  onUpdateSubTabSelection: (data) => {
    dispatch(updateSubTabSelection(data));
  },
  onUpdateWarningMessage: (data) => {
    dispatch(updateOpenWarningMessage(data));
  },
  onUpdateNextPageToNavigate: (data) => {
    dispatch(updateNextPageToNavigate(data));
  },
});
const headerMenuRouterWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(routerWrapper);

export default withStyles(useStyle)(headerMenuRouterWrapper);
