import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";
import { Typography, Grid, useTheme } from "@mui/material";
import { ReadMore } from "../customComponent/readMore";
import { commonLeftCompStyle } from "../css/commonCssLeftComp";

export default function DescriptionIndicator({
  myIndicator,
  indicatorDescription,
  descTypoCustomClass = "",
  descGridCustomClass = "",
  customStyles = "",
  specialProp = "",
  lg = "",
  md = "",
  sm = "",
  xs = "",
  t,
}) {
  const store = useSelector(getStore);
  const { isScenarioEnabled } = store.value;
  const theme = useTheme();
  const classes = commonLeftCompStyle(theme);
  const pnav = i18next.getFixedT(null, null, "pageNavigation");
  const gdpInUSD =
    indicatorDescription === "GDPPerCapita"
      ? `${t(indicatorDescription)}  (in USD ${specialProp})`
      : "";

  if (myIndicator === "baseSaveFeedback") {
    return (
      <Grid>
        <Typography sx={classes.saveFeedback}>
          {isScenarioEnabled ? (
            <ReadMore text={pnav("scenSaved")} />
          ) : (
            <ReadMore text={pnav("baseSaved")} />
          )}
        </Typography>
      </Grid>
    );
  } else {
    return (
      <Grid
        item
        lg={lg}
        md={md}
        sm={sm}
        xs={xs}
        sx={descGridCustomClass}
        style={{ ...customStyles }}
      >
        <Typography sx={descTypoCustomClass}>
          <ReadMore
            text={
              indicatorDescription === "GDPPerCapita"
                ? gdpInUSD
                : t(indicatorDescription)
            }
          />
        </Typography>
      </Grid>
    );
  }
}
