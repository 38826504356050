export default {
  GET: (req) => request("GET", req),
  POST: (req) => request("POST", req),
  PATCH: (req) => request("PATCH", req),
};

export function getReqOptions(method, { headers, body }) {
  const reqHeaders = {
    "Content-Type": "application/json",
    ...headers,
  };

  const requestOptions = {
    method,
    mode: "cors",
  };
  if (method === "POST") {
    reqHeaders["Content-Type"] = "application/json";
    requestOptions.body = JSON.stringify(body);
  } else if (method === "PATCH") {
    reqHeaders["Content-Type"] = "application/json-patch+json";
    requestOptions.body = JSON.stringify(body);
  }
  requestOptions.headers = reqHeaders;
  return requestOptions;
}

function request(method, reqOptions) {
  const url = reqOptions.endpoint;
  const options = getReqOptions(method, reqOptions);
  return fetch(url, options).then(checkStatus).then(parseJSON);
}

function checkStatus(response) {
  // need to check the repsonse status
  if (response.status === 200) {
    return response;
  } else if (response.status === 500) {
    window.location.assign("https://www.imf.org/external/error.htm");
  }
  return response.json().then((error) => {
    error.error.status = response.status;
    throw error;
  });
}

function parseJSON(response) {
  return response.json();
}
