import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./LangResource/resource";
import store from "./store/store";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { THEME } from "./css/typography";
import "./css/index.css";

    i18n.on("initialized", () => {
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store}>
            <CssBaseline>
              <I18nextProvider i18n={i18n}>
                <ThemeProvider theme={THEME}>
                 <App />
                </ThemeProvider>
              </I18nextProvider>
            </CssBaseline>
          </Provider>
        </React.StrictMode>,
        document.getElementById("root")
      );
  });

