import React, { useEffect } from "react";
import { Typography, Grid, Hidden } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import FinanceNeeds from "../commonComponents/FinanceNeeds";
import AchieveGoal from "../commonComponents/achieveGoal";
import MacroIndicators from "../baseline/MacroIndicator";
import GovFinance from "../baseline/GovFinance";
import LineGraphDevExtreme from "../graph/lineGraphDevExtreme";

const breakPoint = makeStyles(() => ({
  content: {
    backgroundColor: "#F8FAFC",
  },
  macroIndicators: {
    backgroundColor: "#EEF4FA",
    padding: 10,
    paddingRight: 28.8,
    paddingLeft: 28.8,
  },
  paddingY: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  tableMarginTop: {
    marginTop: 40,
  },
  chart: {
    height: "70%",
    width: "100%",
  },
  mainHeading: {
    "font-family": "'MuseoSans 500'",
    fontSize: [18, "!important"],
    color: "#E35205",
    padding: "10px",
  },
  achieveGoals: {
    padding: "5px",
    border: "solid",
    borderWidth: "1.5px",
    borderRadius: "15px",
    borderColor: "#009CDE",
    backgroundColor: "#ffffff",
    paddingRight:"25px"
  },
  financeNeeds: {
    padding: "5px",
    marginTop: "25px",
    border: "solid",
    borderWidth: "1.5px",
    borderRadius: "15px",
    borderColor: "#009CDE",
    backgroundColor: "#ffffff",
  },
  macroPadding: {
    paddingTop: "25px",
  },
}));
function ComparisonDashboard() {
  const classes = breakPoint();
  const store = useSelector(getStore);
  const yearList = store.value.yearList;
  const { baselineChartsData, scenarioChartData, dashboardBackupData } =
    store.compDashData;

  const { projectionPeriods, data, planningYear} = dashboardBackupData;
  const scenYearToAchieve = data.scenario.yearToAchieve;
  const baseYearToAchieve = data.baseline.yearToAchieve;
  const scenAddNeeds = data.scenario.additionalFinancingNeeds;
  const baseAddNeeds = data.baseline.additionalFinancingNeeds;
  const humanCapitaPerWork = store.baselineScenarioData.firstYearProjections;

  useEffect(() => {}, [store.compDashData]);

  const humanCapitalLabels = [
    {
      value: "baseHuman",
      name: "Baseline",
      color: "#00C800",
    },
    {
      value: "scenHuman",
      name: "Scenario",
      color: "#3498DB",
    },
  ];
  const infraCapitalLabels = [
    {
      value: "baseInfra",
      name: "Baseline",
      color: "#00C800",
    },
    {
      value: "scenInfra",
      name: "Scenario",
      color: "#3498DB",
    },
  ];
  const realGDPLabels = [
    {
      value: "baseReal",
      name: "Baseline",
      color: "#00C800",
    },
    {
      value: "scenReal",
      name: "Scenario",
      color: "#3498DB",
    },
  ];

  let humanCapitalData = [];
  let infraCapitalData = [];
  let realGDPData = [];

  for (let i = 0; i <= yearList.length; i++) {
    humanCapitalData.push({
      year: yearList[i],
      baseHuman: baselineChartsData.humanCapitalAccumulation[i],
      scenHuman: scenarioChartData.humanCapitalAccumulation[i],
    });
    infraCapitalData.push({
      year: yearList[i],
      baseInfra: baselineChartsData.infrastructureCapital[i],
      scenInfra: scenarioChartData.infrastructureCapital[i],
    });
    realGDPData.push({
      year: yearList[i],
      baseReal: baselineChartsData.realGdpPerCapita[i],
      scenReal: scenarioChartData.realGdpPerCapita[i],
    });
  }

  return (
    <div id="exportPDF">
      <Grid container className={classes.content}>
        <Grid item lg={6} md={6} sm={12} xs={12} container>
          <Grid container className={classes.macroIndicators}>
            {/* left side component to be rendered */}

            <Hidden only={["md", "lg", "xl", "sm", "xs"]}>
              <Typography className={classes.mainHeading} id="header">
                {"Year to achieve SDGs in five sectors"}
              </Typography>

              <Grid item>
                <Grid item xs={12}>
                  <AchieveGoal
                    dashProjection={projectionPeriods}
                    dashYearToAchieve={baseYearToAchieve}
                    dashLabel={"Baseline"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AchieveGoal
                    dashProjection={projectionPeriods}
                    dashYearToAchieve={scenYearToAchieve}
                    dashLabel={"Scenario"}
                  />
                </Grid>
              </Grid>
              <Typography className={classes.mainHeading} id="header">
                {`Financing needs to reach SDGs by ${planningYear} per year ( % of GDP)`}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <FinanceNeeds
                    dashFinanceNeeds={baseAddNeeds}
                    dashLabel={"Baseline"}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FinanceNeeds
                    dashFinanceNeeds={scenAddNeeds}
                    dashLabel={"Scenario"}
                  />
                </Grid>
              </Grid>
            </Hidden>
            <Grid container direction="column">
              <Grid container item className={classes.achieveGoals}>
                <Grid item xs={12}>
                  <Typography className={classes.mainHeading}>
                    {"Year to achieve SDGs in five sectors"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AchieveGoal
                    dashProjection={projectionPeriods}
                    dashYearToAchieve={baseYearToAchieve}
                    dashLabel={"Baseline"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AchieveGoal
                    dashProjection={projectionPeriods}
                    dashYearToAchieve={scenYearToAchieve}
                    dashLabel={"Scenario"}
                  />
                </Grid>
              </Grid>

              <Grid container item className={classes.financeNeeds}>
                <Grid item xs={9}>
                  <Typography className={classes.mainHeading}>
                    {
                      `Financing needs to reach \
                      SDGs by ${planningYear} per year ( % of GDP)`
                    }
                  </Typography>
                </Grid>

                <Grid item xs={6} xl={6}>
                  <FinanceNeeds
                    dashFinanceNeeds={baseAddNeeds}
                    dashLabel={"Baseline"}
                  />
                </Grid>
                <Grid item xs={6} xl={6}>
                  <FinanceNeeds
                    dashFinanceNeeds={scenAddNeeds}
                    dashLabel={"Scenario"}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item className={classes.macroPadding}>
                  <MacroIndicators />
                </Grid>
                <Grid item className={classes.macroPadding}>
                  <GovFinance />
                </Grid>
              </Grid>
              <Hidden only={["md", "lg", "xl"]}>
                <Grid item className={classes.macroPadding}>
                  <LineGraphDevExtreme
                    chartDataSource={realGDPData}
                    chartLabels={realGDPLabels}
                    chartTitle={"Real GDP per Capita"}
                    chartSubTitle={"(in billions of national currency)"}
                  />
                  <LineGraphDevExtreme
                    chartDataSource={infraCapitalData}
                    chartLabels={infraCapitalLabels}
                    chartTitle={"Infrastructure Capital"}
                    chartSubTitle={"(in percent of GDP)"}
                  />
                  <LineGraphDevExtreme
                    chartDataSource={humanCapitalData}
                    chartLabels={humanCapitalLabels}
                    chartTitle={"Human Capital Accumulation"}
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={["xs", "sm"]}>
          <Grid item lg={6} md={6} sm={6} xs={12} className={classes.paddingY}>
            <Grid className={classes.tableMarginTop} item xs={12}>
              <LineGraphDevExtreme
                chartDataSource={realGDPData}
                chartLabels={realGDPLabels}
                chartTitle={"Real GDP per Capita"}
                chartSubTitle={"(in billions of national currency)"}
              />
            </Grid>
            <Grid className={classes.tableMarginTop} item xs={12}>
              <LineGraphDevExtreme
                chartDataSource={infraCapitalData}
                chartLabels={infraCapitalLabels}
                chartTitle={"Infrastructure Capital"}
                chartSubTitle={"(in percent of GDP)"}
              />
            </Grid>
            <Grid className={classes.tableMarginTop} item xs={12}>
              <LineGraphDevExtreme
                chartDataSource={humanCapitalData}
                chartLabels={humanCapitalLabels}
                chartTitle={"Human Capital Per Worker"}
                chartSubTitle={`(Index = ${humanCapitaPerWork})`}
              />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
}

export const createPDF = async () => {
  const pdf = new jsPDF("portrait", "pt", "a4");
  const data = await html2canvas(document.querySelector("#exportPDF"));
  const img = data.toDataURL("image/png");
  const imgProperties = pdf.getImageProperties(img);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
  pdf.save("Comparison_Dashboard.pdf");
};

export default ComparisonDashboard;
