import { all, takeLatest, put, call, select } from "redux-saga/effects";
import {
  addCountryList,
  addAdvPhyCap,
  addAdvHumCap,
  addInitLevels,
  updateDebtFinancing,
  addPrivateFinanceData,
  addRevenue,
  addChartData,
  getSDGBaselineScenarioData,
  getScenarioDashboardData,
  updateRevenues,
  updateStateNotSaved,
  getResponseStatus,
  updateFlagWarningMessage,
  updateSavedFromNavBtn,
  resetSingleItemFromState,
  getStore,
} from "../store/reducerSlice";
import {
  getSDGandResetData,
  getData,
  saveData,
  retrieveSession,
} from "./endPoints";
import { mapCountryData } from "./mappings";

function* getCountryList() {
  try {
    const result = yield call(getData, "api/OnFetchSdgFinCountries");
    yield put(addCountryList(mapCountryData(result)));
  } catch (error) {
    yield console.log(error);
  }
}

function* getBaselineData(data) {
  delete data.type;
  const bodyObj = { country: data.country || "" };
  try {
    const result = yield call(
      getSDGandResetData,
      "api/OnFetchSdgFinData",
      bodyObj
    );
    yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield console.log(error);
  }
}

function* getSaveData() {
  const { value, baseScenDataToSend } = yield select(getStore);
  const savedFromNav = value.savedFromNavigationBtn;
  const baseOrScenMethod = value.isScenarioEnabled
    ? "api/OnChangeScenarioUserSettings"
    : "api/OnChangeUserSettings";

  try {
    const result = yield call(
      saveData,
      baseOrScenMethod,
      baseScenDataToSend,
      value.isScenarioEnabled
    );
    yield put(getResponseStatus("success"));
    yield put(updateStateNotSaved(false));
    yield put(getSDGBaselineScenarioData(result));
    if (!savedFromNav) {
      yield put(updateFlagWarningMessage("sucess"));
    }
    yield put(updateSavedFromNavBtn(false));
  } catch (error) {
    yield put(getResponseStatus(error));
    yield console.log(error);
  }
}

function* getSessionData(data) {
  delete data.type;
  const bodyObj = { sessionId: data.uniqueKey || "" };
  try {
    const result = yield call(
      retrieveSession,
      "api/OnFetchSdgFinSession",
      bodyObj
    );
    yield put(getResponseStatus(""));
    yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield put(getResponseStatus(error));
    yield console.log(error);
  }
}

function* resetAllBaseline() {
  const { baselineScenarioData, value } = yield select(getStore);

  const baseOrScenMethod = value.isScenarioEnabled
    ? "api/OnResetSdgFinScenario"
    : "api/OnFetchSdgFinData";

  const resetValues = {
    country: baselineScenarioData.country,
    uniqueKey: baselineScenarioData.uniqueKey,
  };
  try {
    const result = yield call(
      getSDGandResetData,
      baseOrScenMethod,
      resetValues
    );
    yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield console.log(error);
  }
}

function* getSingleReset({ data }) {
  delete data.type;
  const { baselineScenarioData, value } = yield select(getStore);

  const baseOrScenMethod = value.isScenarioEnabled
    ? "api/OnResetSdgFinScenarioUserSettings"
    : "api/OnResetSdgFinUserSettings";
  const bodyObj = {
    uniqueKey: baselineScenarioData.uniqueKey || "",
    country: baselineScenarioData.country,
    setting: data.routePath,
  };
  try {
    const result = yield call(getSDGandResetData, baseOrScenMethod, bodyObj);
    yield put(resetSingleItemFromState(result));
    //yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield console.log(error);
  }
}

// ------------------ SCENARIO ------------------------- //

function* getScenarioData(data) {
  delete data.type;
  const { baselineScenarioData } = yield select(getStore);
  const bodyObj = {
    country: baselineScenarioData.country || "",
    uniqueKey: baselineScenarioData.uniqueKey || "",
  };
  try {
    const result = yield call(
      getSDGandResetData,
      "api/OnFetchSdgFinScenario",
      bodyObj
    );
    yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield console.log(error);
  }
}

// ------------------ DASHBOARD ------------------------- //

function* getDashboardData(data) {
  delete data.type;
  const { baselineScenarioData } = yield select(getStore);
  const bodyObj = {
    country: baselineScenarioData.country || "",
    uniqueKey: baselineScenarioData.uniqueKey || "",
  };
  try {
    const result = yield call(
      getSDGandResetData,
      "api/OnCompareSdgFinBaseline2Scenario",
      bodyObj
    );
    yield put(getScenarioDashboardData(result));
  } catch (error) {
    yield console.log(error);
  }
}

function* exportDashboardData(data) {
  delete data.type;
  const { baselineScenarioData } = yield select(getStore);
  const bodyObj = {
    country: baselineScenarioData.country || "",
    uniqueKey: baselineScenarioData.uniqueKey || "",
  };
  try {
    const result = yield call(
      getSDGandResetData,
      "api/OnExportSdgFinComparisonData",
      bodyObj
    );
    yield put(getScenarioDashboardData(result));
  } catch (error) {
    yield console.log(error);
  }
}

// ------------------ DASHBOARD ------------------------- //

/* Test func for Patch Data
function* getSaveDataPatch({ patch }) {
  delete patch.type;
  const myBaseline = yield select(getStore);

  const patchData = {
    country: myBaseline.baselineScenarioData.country,
    uniqueKey: myBaseline.baselineScenarioData.uniqueKey,
    patch,
  };

  try {
    const result = yield call(
      patchDataReq,
      "api/OnChangeUserSettings",
      patchData
    );
    yield put(getSDGBaselineScenarioData(result));
  } catch (error) {
    yield console.log(error);
  }
} */

function* getUpdateRevenues(data) {
  yield put(updateRevenues(data));
}

function* getChartData(data) {
  try {
    const result = yield call(
      getData,
      `api/ChartData/${data.year}/${data.country}`
    );
    yield put(addChartData(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getPhyCap(data) {
  try {
    const result = yield call(
      getData,
      `api/PhysicalCapitalStock/${data.year}/${data.country}`
    );
    yield put(addAdvPhyCap(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getHumCap(data) {
  try {
    const result = yield call(
      getData,
      `api/HumanCapital/${data.year}/${data.country}`
    );
    yield put(addAdvHumCap(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getInitLevel(data) {
  try {
    const result = yield call(
      getData,
      `api/CostingInitialLevel/${data.year - 1}/${data.country}`
    );
    yield put(addInitLevels(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getDebtFinancing(data) {
  try {
    const result = yield call(
      getData,
      `api/DebtFinancing/${data.year}/${data.country}`
    );
    yield put(updateDebtFinancing(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getRevenue(data) {
  try {
    const result = yield call(
      getData,
      `api/FinancingRevenue/${data.year}/${data.country}`
    );
    yield put(addRevenue(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* getPrivateFinancingData(data) {
  try {
    const result = yield call(
      getData,
      `api/PrivateFinancing/${data.year}/${data.country}`
    );
    yield put(addPrivateFinanceData(result.data));
  } catch (error) {
    yield console.log(error);
  }
}

function* saveRevenueData(data) {
  try {
    const { year, countryCode, value1, value2, value3, isThirdChanged, url } =
      data.data || {};
    const completeUrl = `${url}/${year}/${countryCode}/${value1}/${value2}/${value3}/${
      isThirdChanged ? "Y" : "N"
    }`;
    yield call(getData, completeUrl);
  } catch (error) {
    yield console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest("GET_COUNTRY_DATA", getCountryList),
    takeLatest("RESET_ALL_BASELINE", resetAllBaseline),
    takeLatest("GET_BASELINE_DATA", getBaselineData),
    takeLatest("GET_SESSION_DATA", getSessionData),
    takeLatest("GET_SINGLE_RESET", getSingleReset),
    takeLatest("GET_SAVE_DATA", getSaveData),
    takeLatest("GET_SCENARIO_DATA", getScenarioData),
    takeLatest("GET_DASHBOARD_DATA", getDashboardData),
    takeLatest("EXPORT_DASHBOARD_DATA", exportDashboardData),
    takeLatest("GET_ADVANCED_PHYSICAL_CAPITAL_STOCK_DATA", getPhyCap),
    takeLatest("GET_ADVANCED_HUMAN_CAPITAL_DATA", getHumCap),
    takeLatest("GET_COSTING_INITIAL_LEVELS", getInitLevel),
    takeLatest("GET_DEBT_FINANCING_DATA", getDebtFinancing),
    takeLatest("GET_REVENUE", getRevenue),
    takeLatest("GET_PRIVATE_FINANCING_DATA", getPrivateFinancingData),
    takeLatest("SAVE_REVENUE", saveRevenueData),
    takeLatest("SAVE_MACRO_GDP", saveRevenueData),
    takeLatest("SAVE_MACRO_EXTERNAL_DATA", saveRevenueData),
    takeLatest("UPDATE_REVENUES", getUpdateRevenues),
    takeLatest("GET_CHART_DATA", getChartData),
  ]);
}
