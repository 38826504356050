import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { achieveSector } from "../css/custom";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";

export default function FinanceNeeds({
  dashFinanceNeeds = "",
  dashLabel = "",
}) {
  const classes = achieveSector();
  const store = useSelector(getStore);
  const projectionNeed = store.baselineScenarioData.additionalFinancingNeeds;
  const dashBoardEnabled = store.value.isDashboardEnabled;

  if (dashBoardEnabled) {
    return (
      <Box
        borderRadius={4}
        p={3}
        component="div"
        className={` ${classes.boxMargin}`}
      >
        <Box textAlign="center" fontSize={18} className={classes.headerFont}>
          {dashLabel}
        </Box>
        <Typography
          component="div"
          className={
            dashLabel === "Baseline"
              ? classes.dashBaseAchieve
              : classes.dashScenAchieve
          }
        >
          <Box textAlign="center" fontSize={40} py={1.5}>
            {Math.round(dashFinanceNeeds * 10) / 10 + "%"}
          </Box>
        </Typography>
      </Box>
    );
  } else {
    return (
      <Grid container className={classes.achieveGoals}>
        <Grid item xs={9} className={classes.achieveFont}>
          {"Financing needs to reach SDGs by "}
          <span
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className="boldYear"
          >
            {store.value.selectedFinanceYear}
          </span>
          {"  per year (% of GDP)"}
        </Grid>
        <Grid container justifyContent="center">
          <Typography className={classes.beyondAchieve}>
            {Math.round(projectionNeed * 10) / 10 + "%"}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}
