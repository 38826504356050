import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography, Link } from "@material-ui/core";
import { getStore } from "../store/reducerSlice";
import { baselineStyles } from "../css/custom";
import CustomNextButton from "../customComponent/customNextButton";

export default function ResetWarningMessage(props) {
  const classes = baselineStyles();
  const store = useSelector(getStore);
  const mainTabsWarningMsg = store.value.mainTabsWarningMsg;

  return (
    <Box item className={classes.modal}>
      <Box
        alignContent="center"
        alignItems="center"
        className={classes.modalContent}
      >
        <Grid>
          <Grid>
            <Typography className={classes.contentHeader}>
              Confirm Reset
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.noteAlert}>
              This will reset all of the data to the default values, including
              saved data.
            </Typography>
          </Grid>
        </Grid>
        <CustomNextButton
          variant="contained"
          onClick={props.proceedAndReset}
          style={{ marginTop: mainTabsWarningMsg ? "" : "20px" }}
        >
          {"Yes, Reset"}
        </CustomNextButton>
        <Box className={classes.noteAlert}>
          <Link
            href="#"
            underline="hover"
            onClick={props.cancelResetWarningMsg}
          >
            {"CANCEL"}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
