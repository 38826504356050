import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { getStore } from "../store/reducerSlice";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import exportIcon from "../assets/export.svg";
import {
  xlRows,
  data,
  srcCells,
  allBorder,
  lrBorder,
  lbrBorder,
  bBorder,
  rBorder,
  brBorder,
  dataRows,
} from "./ExcelInput";

const ExcelJS = require("exceljs");

const ExcelExport = () => {
  const { t } = useTranslation();
  const country = useSelector(getStore);
  const xlData = country.compDashData;
  const [exportData, setExportData] = useState();
  const [startYear, setStartYear] = useState(0);
  const [endYear, setEndYear] = useState(0);

  useEffect(() => {
    if (xlData.dashboardBackupData.data) {
      setStartYear(xlData.dashboardBackupData?.projectionPeriods[0]);
      setEndYear(xlData.dashboardBackupData?.projectionPeriods[2]);
      setExportData(xlRows);
    }
  }, [xlData]);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");
    // sheet.properties.defaultRowHeight = 80;

    const headers = [
      {
        header: "SDG Outcomes",
        key: "col1",
        width: 50,
      },
      {
        header: "\n",
        key: "col2",
      },
      {
        header: "\n",
        key: "col3",
      },
    ];

    function numFormat(sRow, sCol, eCol) {
      //Row,Starting Column, Ending column
      for (let i = sCol; i <= eCol; i++) {
        sheet.getCell(sRow, i).numFmt = "0.0";
      }
    }

    function formatting(cols, fml, fbold, bg, fname, fsize, fcolor) {
      sheet.addConditionalFormatting({
        ref: cols,
        rules: [
          {
            type: "expression",
            formulae: [fml],
            style: {
              fill: {
                type: "pattern",
                pattern: "solid",
                bgColor: { argb: bg },
              },
              font: {
                name: fname,
                bold: fbold,
                size: fsize,
                color: { argb: fcolor },
              },
            },
          },
        ],
      });
    }

    function yearlyData(initialYear, endYear) {
      const arr = [];
      let startYear = initialYear;
      while (startYear < endYear + 1) {
        let i = { header: "\n", key: `${startYear}` };
        arr.push(i);
        startYear++;
      }
      for (let i = 0; i < arr.length; i++) {
        headers.push(arr[i]);
      }
      return headers;
    }

    function yearlyChartData(startYear, endYear, index, path, indicator, tab) {
      let j = 0;
      if (indicator) data[index]["col1"] = indicator;
      data[index]["col2"] = tab;

      while (startYear < endYear + 1) {
        data[index][startYear] = path[j];
        j++;
        startYear++;
      }

      return data;
    }

    function yearlyChartLabel(startYear, endYear, index, title) {
      while (startYear < endYear + 1) {
        data[index]["col1"] = title;
        data[index][startYear] = startYear;
        startYear++;
      }

      return data;
    }

    //Apply the border for individual cells on rows
    function singleCellBorder(sRow, sCol, eCol, t, l, b, r) {
      for (let i = sCol; i <= eCol; i++) {
        sheet.getCell(sRow, i).border = {
          top: { style: t },
          left: { style: l },
          bottom: { style: b },
          right: { style: r },
        };
      }
    }

    //Check Year to Achieve and return valid result
    function getYearToAchieve(yearToAchieve) {
      return yearToAchieve === -1
        ? `${t("BeyondAchieve")} ${endYear}`
        : yearToAchieve;
    }
    function populateSheet() {
      yearlyData(startYear, endYear);
      sheet.columns = headers;
      data[0]["col3"] = getYearToAchieve(
        xlData.dashboardBackupData.data.baseline.yearToAchieve
      );
      data[1]["col3"] = getYearToAchieve(
        xlData.dashboardBackupData.data.scenario.yearToAchieve
      );
      data[2]["col3"] =
        xlData.dashboardBackupData.data.baseline.additionalFinancingNeeds;
      data[3]["col3"] =
        xlData.dashboardBackupData.data.scenario.additionalFinancingNeeds;

      //***************Macroeconomic Indicators***************//
      yearlyChartLabel(startYear, endYear, 4, "Macroeconomic Indicators");
      //*****GDP per capita (in USD 2022)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        5,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .perCapitaGdpFull,
        "GDP per capita (in USD 2022)",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        6,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .perCapitaGdpFull,
        null,
        "Scenario"
      );
      //*****Real GDP growth (in percent)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        7,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .realGdpGrowthFull,
        "Real GDP growth (in percent)",
        "Baselilne"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        8,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .realGdpGrowthFull,
        null,
        "Scenario"
      );
      //*****Current account balance (in percent of GDP)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        9,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .currentAccountBalanceFull,
        "Current account balance (in percent of GDP)",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        10,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .currentAccountBalanceFull,
        null,
        "Scenario"
      );

      //***************Government finances (in percent of GDP)***************//
      yearlyChartLabel(
        startYear,
        endYear,
        11,
        "Government finances (in percent of GDP)"
      );
      //*****Revenues and grants*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        12,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .revenuesNGrantsFull,
        "Revenues and grants",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        13,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .revenuesNGrantsFull,
        null,
        "Scenario"
      );
      //*****Primary expenditure*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        14,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .primaryXpenditureFull,
        "Primary expenditure",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        15,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .primaryXpenditureFull,
        null,
        "Scenario"
      );
      //*****SDG-related*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        16,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .sdgRelatedFull,
        "SDG-related",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        17,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .sdgRelatedFull,
        null,
        "Scenario"
      );
      //*****Other*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        18,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .otherFull,
        "Other",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        19,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .otherFull,
        null,
        "Scenario"
      );
      //*****Interest expenditure*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        20,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .interestExpenditureFull,
        "Interest expenditure",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        21,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .interestExpenditureFull,
        null,
        "Scenario"
      );
      //*****Overall balance*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        22,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .overallBalanceFull,
        "Overall balance",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        23,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .overallBalanceFull,
        null,
        "Scenario"
      );
      //*****General government debt*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        24,
        xlData.dashboardBackupData.data.baseline.macroEconomicIndicatorsFull
          .generalGovernmentDebtFull,
        "General government debt",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        25,
        xlData.dashboardBackupData.data.scenario.macroEconomicIndicatorsFull
          .generalGovernmentDebtFull,
        null,
        "Scenario"
      );

      //***************Chart data***************//
      yearlyChartLabel(startYear, endYear, 26, "Chart data");
      //*****Real GDP per capita (in billions of national currency)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        27,
        xlData.baselineChartsData.realGdpPerCapita,
        "Real GDP per capita (in billions of national currency)",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        28,
        xlData.scenarioChartData.realGdpPerCapita,
        null,
        "Scenario"
      );
      //*****Infrastructure capital (in percent of GDP)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        29,
        xlData.baselineChartsData.infrastructureCapital,
        "Infrastructure capital (in percent of GDP)",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        30,
        xlData.scenarioChartData.infrastructureCapital,
        null,
        "Scenario"
      );
      //*****Human capital accumulation (index = 2023)*****/
      //Baseline
      yearlyChartData(
        startYear,
        endYear,
        31,
        xlData.baselineChartsData.humanCapitalAccumulation,
        "Human capital accumulation (index = 2023)",
        "Baseline"
      );
      //Scenario
      yearlyChartData(
        startYear,
        endYear,
        32,
        xlData.scenarioChartData.humanCapitalAccumulation,
        null,
        "Scenario"
      );
    }

    function mergeCells() {
      Object.keys(srcCells).map((key) => {
        sheet.mergeCells(key, srcCells[key]);
      });
    }

    function formattingCells() {
      //Title Highlight
      exportData.Columns.forEach((row) => {
        formatting(
          row.col,
          row.fml,
          row.bold,
          row.bg,
          row.fname,
          row.fsize,
          row.fcolor
        );
      });
    }

    function borderingCells() {
      allBorder.forEach((cell) => {
        singleCellBorder(cell, 1, 1, "thin", "thin", "thin", "thin");
      });

      lrBorder.forEach((cell) => {
        singleCellBorder(cell, 2, 2, "none", "thin", "none", "thin");
      });

      lbrBorder.forEach((cell) => {
        singleCellBorder(cell, 2, 2, "none", "thin", "thin", "thin");
      });

      bBorder.forEach((cell) => {
        singleCellBorder(cell, 3, 34, "none", "none", "thin", "none");
      });

      rBorder.forEach((cell) => {
        singleCellBorder(cell, 34, 34, "none", "none", "none", "thin");
      });

      brBorder.forEach((cell) => {
        singleCellBorder(cell, 34, 34, "none", "none", "thin", "thin");
      });
      singleCellBorder(3, 2, 3, "none", "thin", "thin", "thin");
      singleCellBorder(5, 2, 3, "none", "thin", "thin", "thin");
      singleCellBorder(2, 2, 3, "none", "thin", "none", "thin");
      singleCellBorder(4, 2, 3, "none", "thin", "none", "thin");
    }

    function numberFormatCells() {
      dataRows.forEach((row) => {
        numFormat(row, 4, 34);
      });
      numFormat(4, 3, 3);
      numFormat(5, 3, 3);
      return null;
    }

    //Add data rows to excel sheet
    function addRow() {
      data.forEach((row) => {
        sheet.addRow(row);
      });
      return null;
    }

    function exportToExcel(fileName) {
      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: 
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
      return null;
    }
    function prerenderXLSheet() {
      const fileName =
        xlData.dashboardBackupData.country + new Date().toISOString();
      populateSheet();
      addRow();
      mergeCells();
      borderingCells();
      formattingCells();
      numberFormatCells();
      exportToExcel(fileName);
    }
    prerenderXLSheet();
  };

  return (
    <Fragment>
      {xlData && (
        <Button onClick={exportExcelFile}>
          <img
            src={exportIcon}
            style={{
              width: "25px",
              paddingRight: "5px",
              paddingBottom: "8px",
            }}
          />
          <span style={{ paddingLeft: "10px" }}>{"Export to Excel"}</span>
        </Button>
      )}
    </Fragment>
  );
};

export default ExcelExport;
