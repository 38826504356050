export const baseOverviewCssUpdate = (Theme) => ({
  paper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2%",
  },
  tooltip: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    display: "flex",

    alignItems: "center",
  },
  sdgtitle: {
    fontSize: "17px",
    color: "#000000",
    marginBottom: "20px",
  },
  headrow: {
    marginBottom: "3%",
  },
  borderstyle: {
    border: "2px solid #4999d0",
    width: "48px",
    height: "48px",
    "border-radius": "50px",
    [Theme.breakpoints.up("md")]: {
      width: "58px",
      height: "58px",
    },
  },
  circleText: {
    fontSize: "14px",
    "font-family": "'MuseoSans'",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px",
    [Theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  textDivAlign: {
    alignSelf: "center",
  },
  circleDivAlign: {
    textAlign: "-webkit-right",
    [Theme.breakpoints.up("md")]: {
      textAlign: "-webkit-center",
    },
  },
  sdgCostingLable: {
    fontSize: [14, "!important"],
    color: ["#231F20", "!important"],
    "font-family": "'MuseoSans'",
    textAlign: "left",
    [Theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  sdgCostingLableTotal: {
    fontSize: [14, "!important"],
    color: ["#231F20", "!important"],
    "font-family": "'MuseoSans 500'",
    textAlign: "left",
    [Theme.breakpoints.up("md")]: {
      textAlign: "center",
    },
  },
  circleTextTotal: {
    fontSize: "14px",
    "font-family": "'MuseoSans 500'",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px",
    [Theme.breakpoints.up("md")]: {
      fontSize: "18px",
    },
  },
  rowelement: {
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  //for dbet
  alignCenter: {
    "text-align": "center",
  },
  alignSelf: {
    "align-self": "center",
  },
  dataRow: {
    "border-left": "5px solid #ffffff",
    "padding-left": 10,
    height: 50,
    marginBottom: 5,
    "&:hover": {
      "border-left": "5px solid cornflowerblue",
      "box-shadow": `${"rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"}`,
    },
    "&:last-child": {
      marginBottom: 15,
    },
  },
  dataRow1: {
    padding: "10px 0px",
    alignItems: "center",
  },
  dataHeader: {
    height: 50,
    "border-bottom": "1px solid #cccccc",
    marginBottom: 5,
  },
  dataHeaderText: {
    "font-family": "'MuseoSans 500'",
    fontSize: [18, "!important"],
    color: "#E35205",
    padding: "15px"
  },
  yearsHearderText: {
    "font-family": "'MuseoSans 500'",
    fontSize: [14, "!important"],
  },
  marginHeight: {
    marginTop: 20,
  },

  //for fonts of baselineoverview
  bstitle: {
    fontSize: [26, "!important"],
    color: ["#000000", "!important"],
  },
  bscontent: { fontSize: [16, "!important"], color: ["#000000", "!important"] },
  bslable: {
    fontSize: [14, "!important"],
    color: ["#000000", "!important"],
  },
  sectiontitle: {
    fontSize: [18, "!important"],
    color: ["#000000", "!important"],
  },
  title: {
    fontSize: [16, "!important"],
    color: ["#000000", "!important"],
    "font-family": "'MuseoSans 500'",
  },
  innerMacroRow:{
    padding: "0 !important",
    paddingTop: "0 !important"
  },  
  macroEconomic:{
    border: "solid",
    borderWidth: "1.5px",
    borderRadius: "15px",
    borderColor: "#009CDE",
    backgroundColor: "#ffffff",
  },
  costingtitle: {
    "font-family": "'MuseoSans 500'",
    fontSize: [17, "!important"],
    color: ["#121212", "!important"],
    marginBottom: "20px",
  },
  textWrap: {
    color: "#121212",
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2 /* number of lines to show */,
    "-webkit-box-orient": "vertical",
  },
  macroEcoLabels: {
    fontSize: [16, "!important"],
    color: ["#009CDE", "!important"],
    "font-family": "'MuseoSans 700'",
    textAlign: "right"
  },
  subGovFinLabels: {
    fontSize: [14, "!important"],
    color: ["#004C97", "!important"],
    "font-family": "'MuseoSans 700'",
    textAlign: "right"
  },
  costinglable: {
    fontSize: [16, "!important"],
    color: ["#009CDE", "!important"],
    "font-family": "'MuseoSans 700'",
  },

  costinglableforOf: {
    fontSize: [14, "!important"],
    color: ["#231F20", "!important"],
    "font-family": "'MuseoSans 500'",
    paddingLeft: "20px",
    backgroundColor:"rgb(0,156,222, 0.06)",
  },
});
