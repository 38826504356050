import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
  lng: new URLSearchParams(window.location.search).get("lang"),
  fallbackLng: "en",
  debug: false,
  // have a common namespace used around the full app
  ns: ["translation"],
  defaultNS: "translation",
  keySeparator: ".", // we use content as keys

  detection:{
      order: [ "querystring" ],
  },
  backend: {
    loadPath: `/assets/{{lng}}.json?${new Date()}`
  }
});

export default i18n;