import React from "react";
import { Grid, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";
import i18next from "i18next";
import { baseOverviewCssUpdate } from "../css/BaseOverviewCssUpdate";
import FillIndicators from "../commonComponents/FillIndicators";
import DescriptionIndicator from "../commonComponents/DescriptionIndicators";

export default function GovFinance() {
  const theme = useTheme();
  const classes = baseOverviewCssUpdate(theme);
  const store = useSelector(getStore);
  const govFinance =
    store.baselineScenarioData.indicators.overview.governmentFinances || {};
  const macroYears = store.baselineScenarioData.projectionPeriods || [];
  const dashboardEnabled = store.value.isDashboardEnabled;
  const dashBoardYears =
    store.compDashData.dashboardBackupData.projectionPeriods;
  const { baselineGovFin, scenarioGovFin } = store.compDashData;

  const t = i18next.getFixedT(null, null, "MacroIndicator");

  return (
    <Grid container sx={classes.macroEconomic}>
      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={4}
        >
          <DescriptionIndicator
            indicatorDescription={"GovtFin"}
            descTypoCustomClass={classes.dataHeaderText}
            t={t}
            sm={9}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)" }}
        >
          <FillIndicators
            isHeading
            myIndicator={dashboardEnabled ? dashBoardYears : macroYears}
            gridCustomClass={[classes.alignCenter]}
            typoCustomClass={classes.yearsHearderText}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc", padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"RevenueAndGrants"}
            descTypoCustomClass={classes.macroEcoLabels}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.revenuesAndGrants.revenuesNGrants
                : govFinance.revenuesAndGrants.revenuesNGrants
            }
            baseDashIndicator={
              dashboardEnabled
                ? scenarioGovFin.revenuesAndGrants.revenuesNGrants
                : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"PrimaryExp"}
            descTypoCustomClass={classes.macroEcoLabels}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)" }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.primaryExpenditure.primaryXpenditure
                : govFinance.primaryExpenditure.primaryXpenditure
            }
            baseDashIndicator={
              dashboardEnabled
                ? scenarioGovFin.primaryExpenditure.primaryXpenditure
                : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"SDGRelated"}
            customStyles={{ paddingLeft: "20px" }}
            descTypoCustomClass={classes.subGovFinLabels}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)" }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.primaryExpenditure.sdgRelated
                : govFinance.primaryExpenditure.sdgRelated
            }
            baseDashIndicator={
              dashboardEnabled
                ? scenarioGovFin.primaryExpenditure.sdgRelated
                : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc", padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"Other"}
            customStyles={{ paddingLeft: "20px" }}
            descTypoCustomClass={classes.subGovFinLabels}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.primaryExpenditure.other
                : govFinance.primaryExpenditure.other
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioGovFin.primaryExpenditure.other : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc", padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"InterestExp"}
            descTypoCustomClass={classes.macroEcoLabels}
            sm={8}
            t={t}
            
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.interestExpenditure
                : govFinance.interestExpenditure
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioGovFin.interestExpenditure : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc", padding: "10px"  }}
        >
          <DescriptionIndicator
            indicatorDescription={"OverallBal"}
            descTypoCustomClass={classes.macroEcoLabels}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.overallBalance
                : govFinance.overallBalance
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioGovFin.overallBalance : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ paddingBottom: "15px", padding: "10px"  }}
        >
          <DescriptionIndicator
            indicatorDescription={"GenGovtDebt"}
            descTypoCustomClass={classes.costinglable}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)" }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineGovFin.generalGovernmentDebt
                : govFinance.generalGovernmentDebt
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioGovFin.generalGovernmentDebt : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}