import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles({
  more: {
    color: "#234E90",
    cursor: "pointer",
  },
  boxDisplay: {
    display: "inline",
  },
});

export function ReadMore(props) {
  const classes = useStyle();
  const [value, setValue] = useState(0);
  let charLimit = window && window.matchMedia("(max-width: 420px)").matches
    ? 30
    : window && window.matchMedia("(max-width: 680px)").matches
      ? 50
      : 120;

  return props.text.length <= charLimit ? (
    props.text
  ) : value == 0 ? (
    <>
      {`${props.text.substring(0, charLimit)} ... `}
      <span className={classes.more} onClick={() => setValue(1)}>
        {"More"}
      </span>
    </>
  ) : (
    <>
      {`${props.text} ... `}
      <span className={classes.more} onClick={() => setValue(0)}>
        {"Less"}
      </span>
    </>
  );
}
