import { createTheme } from "@mui/system";

export const THEME = createTheme({
        "html": {
            "-webkit-font-smoothing": "antialiased",
            "-moz-osx-font-smoothing": "grayscale",
            "fontFamily": "MuseoSans",
            "fontSize": 14,
            color: "#000000"
        },
        "body": {
            "-webkit-font-smoothing": "antialiased",
            "-moz-osx-font-smoothing": "grayscale",
            "fontFamily": "MuseoSans",
            "fontSize": 14,
            color: "#000000"
        },
        "*, *::before, *::after": {
            "fontFamily": "MuseoSans",
            "fontSize": 14,
            color: "#000000"
        },
    typography: {
        "fontFamily": "MuseoSans",
        "fontSize": 14,
        fontWeight: "normal"
    },
    Box: {
        "fontFamily": "MuseoSans",
        "fontSize": 14,
        fontWeight: "normal"
    },
    button: {
        "fontFamily": "MuseoSans",
        "fontSize": 14,
    },
});