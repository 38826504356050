import React from "react";
import { Grid, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { getStore } from "../store/reducerSlice";
import i18next from "i18next";
import { baseOverviewCssUpdate } from "../css/BaseOverviewCssUpdate";
import FillIndicators from "../commonComponents/FillIndicators";
import DescriptionIndicator from "../commonComponents/DescriptionIndicators";

export default function MacroIndicators() {
  const theme = useTheme();
  const classes = baseOverviewCssUpdate(theme);
  const store = useSelector(getStore);
  const macroIndicators =
    store.baselineScenarioData.indicators.overview.macroEconomicIndicators ||
    {};

  const macroYears = store.baselineScenarioData.projectionPeriods || [];
  const dashboardEnabled = store.value.isDashboardEnabled;
  const dashBoardYears =
    store.compDashData.dashboardBackupData.projectionPeriods;
  const { baselineMacro, scenarioMacro, scenMacroGDP, baseMacroGDP } =
    store.compDashData;
  const gdpPerCapita = store.baselineScenarioData.firstYearProjections - 1;


  const t = i18next.getFixedT(null, null, "MacroIndicator");

  return (
    <Grid container sx={classes.macroEconomic}>
      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          xs={4}
        >
          <DescriptionIndicator
            indicatorDescription={"pageHeading"}
            descTypoCustomClass={classes.dataHeaderText}
            t={t}
            sm={8}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)" }}
        >
          <FillIndicators
            isHeading
            myIndicator={dashboardEnabled ? dashBoardYears : macroYears}
            gridCustomClass={[classes.alignCenter]}
            typoCustomClass={classes.yearsHearderText}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc",  padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"GDPPerCapita"}
            descTypoCustomClass={classes.macroEcoLabels}
            specialProp={gdpPerCapita}
            sm={8}
            t={t}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baseMacroGDP
                : macroIndicators.perCapitaGdp
            }
            baseDashIndicator={
              dashboardEnabled ? scenMacroGDP : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={[classes.costinglable]}
            indicatorDescription={"GDPPerCapita"}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{ "border-bottom": "1px solid #cccccc",  padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"realGDPGrowth"}
            descTypoCustomClass={classes.macroEcoLabels}
            t={t}
            sm={8}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{
            backgroundColor: "rgb(0,156,222, 0.06)",
            "border-bottom": "1px solid #cccccc",
          }}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineMacro.realGdpGrowth
                : macroIndicators.realGdpGrowth
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioMacro.realGdpGrowth : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={classes.innerMacroRow}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={4}
          sx={{paddingBottom: "15px",  padding: "10px" }}
        >
          <DescriptionIndicator
            indicatorDescription={"CurrAccBal"}
            descTypoCustomClass={classes.macroEcoLabels}
            t={t}
            sm={8}
          />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          xs={8}
          sx={{ backgroundColor: "rgb(0,156,222, 0.06)"}}
        >
          <FillIndicators
            myIndicator={
              dashboardEnabled
                ? baselineMacro.currentAccountBalance
                : macroIndicators.currentAccountBalance
            }
            baseDashIndicator={
              dashboardEnabled ? scenarioMacro.currentAccountBalance : []
            }
            gridCustomClass={[classes.alignCenter, classes.alignSelf]}
            typoCustomClass={classes.costinglable}
            lg={4}
            md={4}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
